import React, { useEffect, useMemo, useRef, useState } from "react";
import PrioritySendDocsChat from "./PrioritySendDocsChat";
import {
  findPriorityPageType,
  precisedDocsCodes,
  procedureNameByType,
} from "./PriorityData";
import PriorityDocuments from "./PriorityDocuments";
import { getElementOffset, IsJsonString } from "../../libs/helpers";
import { hintLogo } from "../../libs/images";
import ChatUsersPrioritySync from "./ChatUsersPrioritySync";
import localizations from "../../localizations.json";
import { UseFetchGet } from "../../hooks/fetchFunctions";
import { createPortal } from "react-dom";
import { applicationType } from "../../applicationType";
import { Person } from "@mui/icons-material";
import { CircularProgress, Fab, SvgIcon } from "@mui/material";
import { phoneRegex } from "../../libs/regexes";
import { enqueueSnackbar } from "notistack";
import MassActionsDetector from "./MassActionDetector";

let watcherInterval;
let pageUrl = window.location.href;
let isMainView = false;
let isUserView = false;
let prevIsTableView = false;
let prevView = null;

let shiftPressedCount = 0;
let lastKeyPressTime = 0;
const SHIFT_KEY_CODE = 16;
const TIME_INTERVAL = 500;
let isKeyPressed = false;

const PriorityPlatform = React.memo(
  ({
    userData,
    userDataRef,
    businessInfo,
    businessInfoRef,
    selectedFunnerChatUser,
    handleSelectedFunnerChatUser,
    isPrioritySendDocsWindow,
    handlePrioritySendDocsWindow,
    handleSetNotif,
    templatesList,
    isPriorityDocumentWindow,
    handlePriorityDocumentsWindow,
    funnerUsers,
    chatUsersGlobal,
    chatUsersGlobalRef,
    isActiveAppOnPageRef,
    setOpenUnreadMessagesPopup,
    fetchWhatsappTemplates,
    isUsersPrioritySyncWindow,
    handleUsersPrioritySettingsWindow,
    selectedUsersPrioritySync,
    setSelectedUsersPrioritySync,
    handleSendPriorityUploadState,
    appButtonsRef,
    disableChatGoBack,
    setDisableChatGoBack,
    openUnreadMessagesPopup,
  }) => {
    const [localization, setLocalization] = useState(
      localizations["he"].priority,
    );

    const [isPriorityPage, setIsPriorityPage] = useState(false);
    const [priorityDocumentId, setPriorityDocumentId] = useState(null);
    const [priorityDocTypes, setPriorityDocTypes] = useState([]);
    const [showPriorityBtn, setShowPriorityBtn] = useState(false);
    const [showPriorityActionsBtn, setShowPriorityActionsBtn] = useState(false);

    const [priorityBtnLoading, setPriorityBtnLoading] = useState(false);

    const configurations = useRef({
      closeChatWhenFocusOut: true,
      preciseDocDetection: true,
    });
    const priorityDocumentIdRef = useRef(null);
    const lastFocusedElemRef = useRef(null);
    const docBtnRef = useRef(null);
    const lastDoubleShiftFocusedRef = useRef(null);
    const selectedFunnerUserRef = useRef(null);
    const openUnreadMessagesPopupRef = useRef(null);
    const showPriorityBtnRef = useRef(null);
    const showPriorityBtnRippleRef = useRef(null);
    const priorityDocTypesRef = useRef(null);

    //* Mass Records refs
    const showPriorityBtnActionsRef = useRef(null);

    const handleShowPriorityBtn = (value) => {
      setShowPriorityBtn(value);
      showPriorityBtnRef.current = value;
    };
    const handlePriorityDocTypes = (value) => {
      setPriorityDocTypes(value);
      priorityDocTypesRef.current = value;
    };
    const handleShowPriorityActionsBtn = (value) => {
      setShowPriorityActionsBtn(value);
      showPriorityBtnRef.current = value;
    };

    // const priorityContactLabels = useMemo(() => {
    //   const platformsObject = IsJsonString(businessInfo.CompanyPlatforms)
    //     ? JSON.parse(businessInfo.CompanyPlatforms)
    //     : null;

    //   let labelsObject = {
    //     mainView: {
    //       single: {
    //         phoneLabel: "טלפון",
    //         nameLabel: "מס. לקוח",
    //       },
    //       table: {
    //         phoneLabel: "טלפון",
    //         nameLabel: "שם לקוח",
    //       },
    //     },
    //   };

    //   if (platformsObject) {
    //     if (platformsObject.priority?.configurations?.priorityPlatformLabels) {
    //       const priorityPlatformLabels =
    //         platformsObject.priority?.configurations.priorityPlatformLabels;
    //       if (priorityPlatformLabels.mainViewSingle) {
    //         labelsObject.mainView.single = {
    //           phoneLabel:
    //             priorityPlatformLabels.mainViewSingle.contactPhoneLabel ||
    //             "טלפון",
    //           nameLabel:
    //             priorityPlatformLabels.mainViewSingle.contactNameLabel ||
    //             "מס. לקוח",
    //         };
    //       }
    //       if (priorityPlatformLabels.mainViewTable) {
    //         labelsObject.mainView.table = {
    //           phoneLabel:
    //             priorityPlatformLabels.mainViewTable.contactPhoneLabel || "טלפון",
    //           nameLabel:
    //             priorityPlatformLabels.mainViewTable.contactNameLabel ||
    //             "שם לקוח",
    //         };
    //       }
    //     }
    //   }

    //   return labelsObject;
    // }, [businessInfo?.CompanyPlatforms]);

    const priorityMassActions = useMemo(() => {
      const platformsObject = IsJsonString(businessInfo.CompanyPlatforms)
        ? JSON.parse(businessInfo.CompanyPlatforms)
        : null;

      let massActions = [];

      if (platformsObject?.priority?.massActions) {
        massActions = platformsObject.priority.massActions;
      }

      return massActions;
    }, [businessInfo?.CompanyPlatforms]);

    function priorityWatcher() {
      handlePlatforms(pageUrl);

      // if (watcherInterval) {
      //   clearInterval(watcherInterval);
      // }

      // watcherInterval = setInterval(async () => {
      //   const urlLocal = pageUrl;
      //   const url = window.location.href;
      //   if (urlLocal !== url) {
      //     console.log("PlatformsDebug url changed");
      //     handlePlatforms(url);
      //     pageUrl = window.location.href.toString();
      //   }
      // }, 10);
    }
    function handlePlatforms(url) {
      try {
        const priorityDomain =
          businessInfoRef.current?.CompanyPlatforms &&
          JSON.parse(businessInfoRef.current.CompanyPlatforms)?.priority
            ?.domain;

        const priorityPage = priorityDomain
          ? url.includes(priorityDomain)
          : false;

        setIsPriorityPage(priorityPage);

        console.log("PlatformsDebug priorityPage: " + priorityPage);

        if (priorityPage) {
          let previousActiveNumber = null;
          isMainView = false;
          isUserView = false;
          prevIsTableView = false;
          let documentId = null;
          let lastActiveRow = null;
          let prevPageType = null;

          doubleShiftInputAction();

          watcherInterval = setInterval(() => {
            const headerTitleElement = document.querySelector(
              ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
            )?.children[0];
            const headerTitle =
              headerTitleElement && headerTitleElement.innerText;
            const breadCrumbsParent =
              headerTitleElement?.closest("#reactHeader");
            const isBreadcrumbsVisible =
              breadCrumbsParent &&
              getComputedStyle(breadCrumbsParent)?.getPropertyValue(
                "display",
              ) === "block";
            const isTitleExisting = headerTitle && isBreadcrumbsVisible;

            //* Page type detection
            const invoicePageType =
              isTitleExisting && findPriorityPageType(headerTitle);
            isMainView = isTitleExisting && headerTitle === "לקוחות";
            isUserView = isTitleExisting && headerTitle === "אנשי קשר ללקוח";
            const inputsArr = Array.from(document.querySelectorAll("input"));
            const isTableView = inputsArr.some((input) => {
              const inputTableParent = input.closest(".form-table-view");
              return (
                inputTableParent &&
                !inputTableParent.hasAttribute("aria-hidden")
              );
            });

            //* Invoice (doc) page
            if (invoicePageType) {
              if (prevView !== "invoicePage")
                console.log("Priority Page Type: Invoice");

              //** Invoice page type detection
              if (invoicePageType.type !== prevPageType) {
                console.log(
                  `Priority Page Type: ${invoicePageType.type}, Input Title: ${invoicePageType.inputTitle}`,
                );
                prevPageType = invoicePageType.type;
              } else if (!prevPageType) {
                console.log("Priority Page Type: not Invoice Page");
                prevPageType = "NoInvoice";
              }

              //** Main logic
              const focusedEl = document.activeElement;
              if (focusedEl && focusedEl.tagName === "INPUT") {
                const newDocumentId = focusedEl.value;
                if (
                  newDocumentId &&
                  newDocumentId !== "" &&
                  newDocumentId !== documentId
                ) {
                  // console.log("documentId: ", documentId);
                  // console.log("newDocumentId: ", newDocumentId);
                  handleInvoicePageBtn(invoicePageType, newDocumentId);
                } else if (newDocumentId === "") {
                  handleShowPriorityBtn(null);
                }

                // let isBtn = phoneRegex.test(formattedPhoneNumber);
                // if (isBtn !== (showPriorityBtnRef.current === "contact")) {
                //   handleShowPriorityBtn(isBtn ? "contact" : null);
                // }
              }

              // const invoiceInput = document
              //   .querySelector(
              //     `.formTableView_Painted:not([aria-hidden='true']) div[title="${invoicePageType.inputTitle}"]`,
              //   )
              //   ?.closest("tr")
              //   .querySelector("td:nth-child(3) input");
              // const newDocumentId = invoiceInput ? invoiceInput.value : null;
              // if (
              //   newDocumentId &&
              //   newDocumentId !== "" &&
              //   newDocumentId !== documentId
              // ) {
              //   console.log(`Priority New Document ID: ${newDocumentId}`);
              //   documentId = newDocumentId;
              //   handlePriorityDocumentDialog(documentId);
              // } else if ((!newDocumentId || newDocumentId === "") && documentId) {
              //   console.log("Priority No Document ID");
              //   removePriorityDocumentDialog();
              //   documentId = null;
              // }

              //** Button visibility
              // let isBtn = false;
              // if (isTableView) {
              //   const targetRow = document.querySelector(
              //     ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
              //   );
              //   const parentTable = targetRow.closest(".form-table-view");

              //   if (targetRow && parentTable) {
              //     const phoneColHeadCell = parentTable.querySelector(
              //       `[title="${invoicePageType.inputTitle}"]`,
              //     );
              //     const colNumber = phoneColHeadCell?.parentElement
              //       ? Array.from(phoneColHeadCell.parentElement.children).indexOf(
              //           phoneColHeadCell,
              //         )
              //       : null;

              //     if (colNumber) {
              //       const targetDocInput = targetRow.querySelector(
              //         `td:nth-child(${colNumber + 1}) input`,
              //       );
              //       isBtn = targetDocInput?.value?.length > 0 || false;
              //     }
              //   }
              // } else isBtn = true;
              // if (isBtn !== (showPriorityBtnRef.current === "doc"))
              //   handleShowPriorityBtn(isBtn ? "doc" : null);
            } else if (!invoicePageType && prevPageType !== "NoInvoice") {
              console.log("Priority Page Type: not Invoice Page");
              prevPageType = "NoInvoice";
            }
            if (!invoicePageType) {
              if (priorityDocumentIdRef.current !== null) {
                removePriorityDocumentDialog();
                documentId = null;
              }
            }

            //* User view
            if (isUserView) {
              if (prevView !== "userView")
                console.log("Priority Page Type: User View");

              const parentTable =
                document
                  .querySelector(
                    '.net-form-scroll [id^="FormTableView_Table_Id-"]:not(.form-table-view):not([aria-hidden]) .formGridWidget',
                  )
                  ?.closest(`[id^="FormTableView_Table_Id-"]`) || null;

              //? May be it will be needed
              const isUserTableView = parentTable
                ? !parentTable.hasAttribute("aria-hidden")
                : false;

              const focusedElement = document.activeElement;
              if (focusedElement && focusedElement.tagName === "INPUT") {
                const formattedPhoneNumber = focusedElement.value.replace(
                  /[^0-9]/g,
                  "",
                );
                let isBtn = phoneRegex.test(formattedPhoneNumber);
                if (isBtn !== (showPriorityBtnRef.current === "contact")) {
                  handleShowPriorityBtn(isBtn ? "contact" : null);

                  if (
                    isBtn === false &&
                    selectedFunnerUserRef.current &&
                    openUnreadMessagesPopupRef.current &&
                    configurations.current.closeChatWhenFocusOut
                  ) {
                    setOpenUnreadMessagesPopup(false);
                  }
                }
              }

              if (prevView !== "userView") {
                setDisableChatGoBack(true);
              }
            }
            //* Main view
            else if (isMainView) {
              if (prevView !== "mainView")
                console.log("Priority Page Type: Main View");

              const focusedElement = document.activeElement;
              if (focusedElement && focusedElement.tagName === "INPUT") {
                const formattedPhoneNumber = focusedElement.value.replace(
                  /[^0-9]/g,
                  "",
                );
                let isBtn = phoneRegex.test(formattedPhoneNumber);
                if (isBtn !== (showPriorityBtnRef.current === "contact")) {
                  handleShowPriorityBtn(isBtn ? "contact" : null);
                  if (
                    isBtn === false &&
                    selectedFunnerUserRef.current &&
                    openUnreadMessagesPopupRef.current &&
                    configurations.current.closeChatWhenFocusOut
                  ) {
                    setOpenUnreadMessagesPopup(false);
                  }
                }
              }

              if (prevView !== "mainView") setDisableChatGoBack(true);
            } else {
              if (previousActiveNumber) {
                handleSelectedFunnerChatUser(null);
                previousActiveNumber = null;
              }
            }
            if (
              !isUserView &&
              !invoicePageType &&
              !isMainView &&
              !isTableView &&
              prevView
            ) {
              console.log("No page");
              setDisableChatGoBack(false);
              handleShowPriorityBtn(false);
            }

            //* Saving previous state to compare if next is different
            prevView = invoicePageType
              ? "invoicePage"
              : isUserView
              ? "userView"
              : isMainView
              ? "mainView"
              : null;

            prevIsTableView = isTableView;

            //* Mass actions detection
            if (isTableView && isTitleExisting) {
              const findedAction = priorityMassActions.find(
                (action) => action.screenName === headerTitle,
              );
              if (findedAction) {
                if (!findedAction.isActive) return;
                const targetRow = document.querySelector(
                  ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
                );
                const parentTable =
                  targetRow?.closest(".form-table-view") || null;

                if (!targetRow || !parentTable) return;

                const detectionColumns =
                  findedAction.columnName.split(/\s*,\s*/);
                const headerColCell = parentTable.querySelector(
                  `[title="${detectionColumns[0]}"]`,
                );
                const colNumber = headerColCell?.parentElement
                  ? Array.from(headerColCell.parentElement.children).indexOf(
                      headerColCell,
                    )
                  : null;
                let isBtn = colNumber !== null;

                if (isBtn !== showPriorityBtnActionsRef.current) {
                  handleShowPriorityActionsBtn(isBtn);
                }
              }
            } else {
              if (showPriorityBtnActionsRef.current)
                handleShowPriorityActionsBtn(false);
            }
          }, 200);

          async function handleInvoicePageBtn(invoicePageType, newDocumentId) {
            documentId = newDocumentId;

            let docStatus = false;
            if (configurations.current.preciseDocDetection) {
              docStatus = precisedDocsCodes[invoicePageType.type].some((code) =>
                newDocumentId?.startsWith(code),
              );
            } else {
              docStatus = await getSingleDocStatus(
                invoicePageType,
                newDocumentId,
              );
            }

            // console.log("docStatus: ", docStatus);
            // console.log(showPriorityBtnRef.current === "doc");

            if (docStatus !== (showPriorityBtnRef.current === "doc")) {
              handleShowPriorityBtn(docStatus ? "doc" : null);
            }
            if (docStatus === true || newDocumentId === "")
              handlePriorityDocumentDialog(documentId);
          }
        } else {
          if (isActiveAppOnPageRef.current) {
            const rootElement = document.body;
            const phoneNumbersElements = findIsraeliPhoneNumbers(rootElement);

            if (phoneNumbersElements.length) {
              phoneNumbersElements.forEach((elem) => {
                const element = elem.element;
                const elementCoords = getElementOffset(element);

                const funnerButton = document.createElement("button");
                funnerButton.classList.add(
                  "funner-status__button",
                  "funner-status__button--chat",
                  "funner-status__button--page-inserted",
                );
                funnerButton.addEventListener("click", () => {
                  goToChatWithPriorityUser(elem.phone);
                });
                funnerButton.style.left =
                  elementCoords.left + element.offsetWidth + "px";
                funnerButton.style.top =
                  elementCoords.top + element.offsetHeight / 2 + "px";
                const funnerButtonIcon = document.createElement("img");
                funnerButtonIcon.src = hintLogo;
                funnerButtonIcon.alt = "";

                funnerButton.appendChild(funnerButtonIcon);
                // elem.element.appendChild(funnerButton);
                document.body.appendChild(funnerButton);
              });
            }
          }
          if (showPriorityBtn) handleShowPriorityBtn(false);
          if (disableChatGoBack) setDisableChatGoBack(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    function doubleShiftInputAction() {
      function shiftKeyPressedTwice() {
        shiftPressedCount = 0;
        lastKeyPressTime = 0;
        isKeyPressed = false;

        document.removeEventListener("keyup", onKeyDown);
        // document.removeEventListener("keyup", onKeyUp);

        document.addEventListener("keyup", onKeyDown);
        // document.addEventListener("keyup", onKeyUp);
      }
      shiftKeyPressedTwice();
    }
    function onKeyDown(e) {
      if (
        e.keyCode === SHIFT_KEY_CODE ||
        e.code === SHIFT_KEY_CODE
        // && !isKeyPressed
      ) {
        isKeyPressed = true;
        const currentTime = new Date().getTime();

        if (currentTime - lastKeyPressTime < TIME_INTERVAL) {
          shiftPressedCount++;
        } else {
          shiftPressedCount = 1;
        }

        lastKeyPressTime = currentTime;

        if (shiftPressedCount >= 2) {
          console.log("double shift!");
          onDoubleShift();
          triggerRipple(showPriorityBtnRippleRef);

          shiftPressedCount = 0;
          return true;
        }
      }
      return false;
    }
    function onKeyUp(e) {
      if (e.keyCode === SHIFT_KEY_CODE || e.code === SHIFT_KEY_CODE)
        isKeyPressed = false;
    }
    function onDoubleShift(noClose = false) {
      const focusedEl = document.activeElement;
      const headerTitleElement = document.querySelector(
        ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
      )?.children[0];
      const headerTitle = headerTitleElement && headerTitleElement.innerText;
      const invoicePageType = headerTitle && findPriorityPageType(headerTitle);

      if (!showPriorityBtnRef.current) {
        let resultStr = null;
        if (invoicePageType) {
          const pageTypeObject = priorityDocTypesRef.current?.find(
            (type) => type.docIndetificator === invoicePageType.type,
          );
          resultStr = `כדי לשלוח ${pageTypeObject?.docTitle} יש לנווט לשדה מספר המסמך.`;
        } else if (prevView === "mainView" || prevView === "userView") {
          resultStr =
            "כדי לפתוח את חלון השיחה יש לנווט לשדה עם מספר טלפון תקין.";
        }

        if (resultStr)
          return enqueueSnackbar(resultStr, { variant: "warning" });
        else return null;
      }
      // if (
      //   selectedFunnerUserRef.current &&
      //   openUnreadMessagesPopupRef.current &&
      //   !noClose
      // )
      //   return setOpenUnreadMessagesPopup(false);

      lastDoubleShiftFocusedRef.current = focusedEl;

      // const value = focusedEl.value;

      if (invoicePageType) {
        if (focusedEl && focusedEl.tagName === "INPUT") {
          const documentId = focusedEl.value;
          if (documentId) handlePriorityDocumentsWindow(true);
        }
        // if (prevIsTableView) {
        //   //* Here goes logic for table view docs (open doc)
        //   console.log("invoice page table view double shift");
        //   // const targetRow = focusedEl.closest("tr");

        //   const targetRow = document.querySelector(
        //     ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
        //   );
        //   const parentTable = targetRow.closest(".form-table-view");

        //   if (!targetRow || !parentTable) return;

        //   const phoneColHeadCell = parentTable.querySelector(
        //     `[title="${invoicePageType.inputTitle}"]`,
        //   );
        //   const colNumber = phoneColHeadCell?.parentElement
        //     ? Array.from(phoneColHeadCell.parentElement.children).indexOf(
        //         phoneColHeadCell,
        //       )
        //     : null;

        //   if (colNumber) {
        //     const targetDocInput = targetRow.querySelector(
        //       `td:nth-child(${colNumber + 1}) input`,
        //     );

        //     if (targetDocInput) {
        //       const docId = targetDocInput.value;
        //       if (docId) {
        //         priorityDocumentIdRef.current = docId;
        //         setPriorityDocumentId(docId);
        //         handlePriorityDocumentsWindow(true);
        //       }
        //     }
        //   } else alert(localization.notifications.documents.noDocCell);
        // } else {
        //   //* Here goes logic for card (main) view docs (open doc)
        //   console.log("invoice page main view double shift");
        //   const invoiceInput = document
        //     .querySelector(
        //       `.net-form-scroll .formTableView_Painted:not([aria-hidden='true']) div[title="${invoicePageType.inputTitle}"]`,
        //     )
        //     ?.closest("tr")
        //     .querySelector("td:nth-child(3) input");
        //   const newDocumentId = invoiceInput ? invoiceInput.value : null;
        //   if (newDocumentId) {
        //     handlePriorityDocumentsWindow(true);
        //   }
        // }
      } else if (prevView === "mainView") {
        if (focusedEl && focusedEl.tagName === "INPUT") {
          const formattedPhoneNumber = focusedEl.value.replace(/[^0-9]/g, "");
          const isValid = phoneRegex.test(formattedPhoneNumber);
          if (isValid) {
            goToChatWithPriorityUser(formattedPhoneNumber);
          }
        }
      } else if (prevView === "userView") {
        if (focusedEl && focusedEl.tagName === "INPUT") {
          const formattedPhoneNumber = focusedEl.value.replace(/[^0-9]/g, "");
          const isValid = phoneRegex.test(formattedPhoneNumber);
          if (isValid) {
            goToChatWithPriorityUser(formattedPhoneNumber);
          }
        }
      }
    }
    function findIsraeliPhoneNumbers(node) {
      const elementsWithPhoneNumbers = [];

      const walker = document.createTreeWalker(
        node,
        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
        null,
        false,
      );

      while (walker.nextNode()) {
        const currentNode = walker.currentNode;

        if (currentNode.nodeType === Node.TEXT_NODE) {
          const text = currentNode.textContent;
          const matches = text.match(/^(972|0)5\d{8}$/g);

          if (matches) {
            elementsWithPhoneNumbers.push({
              phone: matches[0].startsWith("0")
                ? "972" + matches[0].slice(1)
                : matches[0],
              element: currentNode.parentElement,
            });
          }
        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          // Обработка элементов (например, input)
          const input = currentNode;
          if (
            input.tagName.toLowerCase() === "input" ||
            input.tagName.toLowerCase() === "textarea"
          ) {
            const value = input.value;
            const matches = value.match(/^(972|0)5\d{8}$/g);

            if (matches) {
              // Если найден номер, добавляем элемент input в массив
              elementsWithPhoneNumbers.push({
                phone: matches[0].startsWith("0")
                  ? "972" + matches[0].slice(1)
                  : matches[0], // Найденный номер телефона
                element: input, // Элемент input
              });
            }
          }
        }
      }

      return elementsWithPhoneNumbers;
    }
    function handleDocBtnClick() {
      if (lastFocusedElemRef.current) {
        setTimeout(() => {
          lastFocusedElemRef.current.focus();
          onDoubleShift();
        }, 10);
      }
    }
    const triggerRipple = (ref) => {
      if (!ref.current) return;
      ref.current.start();
      setTimeout(() => {
        ref.current.stop();
      }, 300);
    };

    function handlePriorityDocumentDialog(docId) {
      priorityDocumentIdRef.current = docId;
      setPriorityDocumentId(docId);
    }
    function removePriorityDocumentDialog() {
      handlePriorityDocumentsWindow(false);
      priorityDocumentIdRef.current = null;
      setPriorityDocumentId(null);
    }

    async function getDocTypes() {
      try {
        const res = await UseFetchGet(
          "/api/priority/getDocTypes",
          undefined,
          null,
          undefined,
          "priority",
        );

        handlePriorityDocTypes(res?.data?.length ? res.data : []);
      } catch (error) {
        console.log("getDocTypes error: ", error);
      }
    }
    async function goToChatWithPriorityUser(phone, userName = null) {
      if (!phone)
        return handleSetNotif(
          localization.notifications.customers.userHaveNoPhone,
          "error",
        );
      setPriorityBtnLoading(true);

      const clearPhone = phone.replaceAll(/\D/g, "");

      try {
        if (!phoneRegex.test(clearPhone)) {
          const user = {
            UserDisplayPhoneNumber: clearPhone,
            FullName: userName || "",
          };
          handleSelectedFunnerChatUser(user);
          setOpenUnreadMessagesPopup(true);
        } else {
          const params = {
            phoneNumberId: clearPhone,
          };
          if (userName) params.userName = userName;
          const res = await UseFetchGet(
            "/api/services/getPriorityChatUserObject",
            params,
            null,
            undefined,
            "history",
          );

          if (res.status === 200) {
            const user = { ...res.data };
            if (userName) user.FullName = userName;
            handleSelectedFunnerChatUser(user);
            setOpenUnreadMessagesPopup(true);
          }
        }
      } catch (error) {
        console.log("getPriorityChatUserObject error: ", error);
      } finally {
        setPriorityBtnLoading(false);
      }
    }
    async function getSingleDocStatus(invoicePageType, docId) {
      try {
        const procedureName = procedureNameByType[invoicePageType.type];
        const res = await UseFetchGet(
          "/api/priority/getPriorityDocsList",
          {
            procedureName: procedureName,
            docType: invoicePageType.type,
            from: "",
            to: "",
            name: "",
            docId: docId,
            filter: "final",
            top: 1,
            skip: 0,
            isStrict: true,
          },
          null,
          undefined,
          "priority",
        );

        if (res?.data?.result) {
          const parsedDocs = IsJsonString(res.data.docs)
            ? JSON.parse(res.data.docs)
            : res.data.docs;
          const newDocsList =
            parsedDocs?.value?.length > 0 ? parsedDocs.value : [];

          return newDocsList.length >= 1;
        } else return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    useEffect(() => {
      if (applicationType === "extension") {
        priorityWatcher();
      }

      getDocTypes();

      const onFocusIn = (event) => {
        if (event.target !== docBtnRef.current)
          lastFocusedElemRef.current = event.target;
      };
      document.addEventListener("focusin", onFocusIn);

      if (businessInfoRef.current?.CompanyPlatforms) {
        const companyPlatforms = JSON.parse(
          businessInfoRef.current.CompanyPlatforms,
        );
        if (companyPlatforms.priority?.configurations) {
          configurations.current = companyPlatforms.priority.configurations;
          if (
            configurations.current.closeChatWhenFocusOut === null ||
            configurations.current.closeChatWhenFocusOut === undefined
          )
            configurations.current.closeChatWhenFocusOut = true;
          if (
            configurations.current.preciseDocDetection === null ||
            configurations.current.preciseDocDetection === undefined
          )
            configurations.current.preciseDocDetection = true;
        }
      }

      return () => {
        document.removeEventListener("focusin", onFocusIn);
        clearInterval(watcherInterval);
      };
    }, []);

    useEffect(() => {
      if (
        isPriorityDocumentWindow === false &&
        lastDoubleShiftFocusedRef.current
      )
        lastDoubleShiftFocusedRef.current.focus();
    }, [isPriorityDocumentWindow]);

    useEffect(() => {
      if (priorityBtnLoading) {
        document.documentElement.style.setProperty(
          "cursor",
          "wait",
          "important",
        );
      } else {
        document.documentElement.style.removeProperty("cursor");
      }
    }, [priorityBtnLoading]);

    useEffect(() => {
      if (!openUnreadMessagesPopup && lastDoubleShiftFocusedRef.current)
        lastDoubleShiftFocusedRef.current.focus();
    }, [openUnreadMessagesPopup]);

    useEffect(() => {
      selectedFunnerUserRef.current = selectedFunnerChatUser;
      openUnreadMessagesPopupRef.current = openUnreadMessagesPopup;
      // showPriorityBtnRef.current = showPriorityBtn;
    }, [selectedFunnerChatUser, openUnreadMessagesPopup]);

    return (
      <>
        {appButtonsRef.current &&
          showPriorityBtn &&
          createPortal(
            // <div
            //   className="funner-status__button"
            //   onClick={() => {
            //     // setPriorityDocSelectedId(priorityDocumentIdRef.current);
            //     // handlePriorityDocumentsWindow(true);
            //     handleDocBtnClick();
            //   }}
            //   ref={docBtnRef}
            // >
            <Fab
              size="small"
              color="primary"
              sx={{
                width: 30,
                minWidth: 30,
                height: 30,
                minHeight: 30,
              }}
              onClick={() => {
                handleDocBtnClick();
              }}
              ref={docBtnRef}
              touchRippleRef={showPriorityBtnRippleRef}
            >
              {priorityBtnLoading ? (
                <CircularProgress size={20} color="customWhite" />
              ) : showPriorityBtn === "doc" ? (
                <SvgIcon sx={{ fontSize: 15 }}>
                  <svg
                    width="64px"
                    height="64px"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M4 1a2 2 0 00-2 2v14a2 2 0 002 2h12a2 2 0 002-2V7.414A2 2 0 0017.414 6L13 1.586A2 2 0 0011.586 1H4zm0 2h7.586L16 7.414V17H4V3zm2 2a1 1 0 000 2h5a1 1 0 100-2H6zm-1 5a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h8a1 1 0 100-2H6z"
                      />{" "}
                    </g>
                  </svg>
                </SvgIcon>
              ) : showPriorityBtn === "contact" ? (
                <Person sx={{ color: "common.white", fontSize: 20 }} />
              ) : null}
            </Fab>,
            appButtonsRef.current,
          )}

        {showPriorityActionsBtn && (
          <MassActionsDetector
            appButtonsRef={appButtonsRef}
            showPriorityActionsBtn={showPriorityActionsBtn}
            priorityMassActions={priorityMassActions}
            priorityBtnLoading={priorityBtnLoading}
          />
        )}

        {isPriorityPage && (
          <PriorityDocuments
            businessInfo={businessInfo}
            isPriorityDocumentWindow={isPriorityDocumentWindow}
            handlePriorityDocumentsWindow={handlePriorityDocumentsWindow}
            priorityDocumentId={priorityDocumentId}
            priorityDocumentIdRef={priorityDocumentIdRef}
            globalID={userData.Udid}
            handleSetNotif={handleSetNotif}
            templatesList={templatesList}
            fetchWhatsappTemplates={fetchWhatsappTemplates}
            handleSendPriorityUploadState={handleSendPriorityUploadState}
            openFunnerChatWithUser={goToChatWithPriorityUser}
            priorityDocTypes={priorityDocTypes}
            funnerUsers={funnerUsers}
            chatUsersGlobal={chatUsersGlobal}
            chatUsersGlobalRef={chatUsersGlobalRef}
          />
        )}

        <PrioritySendDocsChat
          globalID={userDataRef.current.Udid}
          businessInfo={businessInfo}
          currentContact={selectedFunnerChatUser}
          isPrioritySendDocsWindow={isPrioritySendDocsWindow}
          handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
          handleSetNotif={handleSetNotif}
          templatesList={templatesList}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          handleSendPriorityUploadState={handleSendPriorityUploadState}
          priorityDocTypes={priorityDocTypes}
        />

        <ChatUsersPrioritySync
          open={isUsersPrioritySyncWindow}
          handleOpenState={handleUsersPrioritySettingsWindow}
          selectedUsersPrioritySync={selectedUsersPrioritySync}
          setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
        />
      </>
    );
  },
);

export default PriorityPlatform;
