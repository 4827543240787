import { PlaylistAddCheck } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import axios from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { createPortal } from "react-dom";

function MassActionsDetector({
  appButtonsRef,
  showPriorityActionsBtn,
  priorityMassActions,
  priorityBtnLoading,
}) {
  const [showPriorityActionsPopover, setShowPriorityActionsPopover] =
    useState(false);
  const [priorityActionsRecordsList, setPriorityActionsRecords] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState({});

  const priorityActionsBtnRef = React.useRef(null);
  const priorityActionsRecordsListRef = React.useRef([]);
  const selectedRecordsListRef = React.useRef({});
  const priorityActionsScanTimeout = React.useRef(null);

  //* Mass actions priority
  function handleStartScanRecordsForWebhookAction() {
    setShowPriorityActionsPopover(true);

    priorityActionsScanTimeout.current = setInterval(() => {
      const headerTitleElement = document.querySelector(
        ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
      )?.children[0];
      const headerTitle = headerTitleElement && headerTitleElement.innerText;

      const findedAction = priorityMassActions.find(
        (action) => action.screenName === headerTitle,
      );
      const targetRow = document.querySelector(
        ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
      );
      const parentTable = targetRow?.closest(".form-table-view");

      if (!targetRow || !parentTable) return;

      const headerColumns = findedAction.columnName.split(/\s*,\s*/);
      const headerColumnsObjects = headerColumns.map((headerColName) => {
        const headerColCell = parentTable.querySelector(
          `[title="${headerColName}"]`,
        );
        const colNumber = headerColCell?.parentElement
          ? Array.from(headerColCell.parentElement.children).indexOf(
              headerColCell,
            )
          : null;

        return {
          name: headerColName,
          colNumber,
        };
      });

      const tableRows = Array.from(parentTable.querySelectorAll("[__gwt_row]"));
      const columnsObjectsList = tableRows.map((rowNode) => {
        let colObj = {};
        headerColumnsObjects.forEach((headerCol) => {
          if (headerCol.colNumber !== null) {
            const col = rowNode.childNodes[headerCol.colNumber];
            const input = col?.querySelector("input");
            colObj[headerCol.name] = input?.value || "";
          }
        });
        return colObj;
      });

      // Filter duplicates
      const mergedNewArray = [
        ...priorityActionsRecordsListRef.current,
        ...columnsObjectsList.filter((newItem) => {
          return !priorityActionsRecordsListRef.current.some(
            (existingItem) =>
              existingItem[headerColumnsObjects[0]?.name] ===
              newItem[headerColumnsObjects[0]?.name],
          );
        }),
      ];

      handlePriorityMassActionsRecordsList(mergedNewArray);
    }, 500);
  }

  function closePriorityMassActionsScanner() {
    setShowPriorityActionsPopover(false);
    clearInterval(priorityActionsScanTimeout.current);
    setTimeout(() => {
      handlePriorityMassActionsRecordsList([]);
      handleSelectedRecordsList({});
    }, 300);
  }
  const handlePriorityMassActionsRecordsList = (list) => {
    setPriorityActionsRecords(list);
    priorityActionsRecordsListRef.current = list;
  };

  //* Handle checked records
  const handleSelectedRecordsList = (newSelectedRecords) => {
    setSelectedRecords(newSelectedRecords);
    selectedRecordsListRef.current = newSelectedRecords;
  };

  React.useEffect(() => {
    const updatedSelectedRecords = { ...selectedRecordsListRef.current };

    priorityActionsRecordsList.forEach((record) => {
      const recordKey = record[Object.keys(record)[0]];
      if (!(recordKey in updatedSelectedRecords)) {
        updatedSelectedRecords[recordKey] = { value: recordKey, checked: true };
      }
    });

    handleSelectedRecordsList(updatedSelectedRecords);
  }, [priorityActionsRecordsList]);

  const handleCheckboxChange = (recordKey) => {
    const updatedSelectedRecords = {
      ...selectedRecordsListRef.current,
      [recordKey]: {
        ...selectedRecordsListRef.current[recordKey],
        checked: !selectedRecordsListRef.current[recordKey].checked,
      },
    };

    handleSelectedRecordsList(updatedSelectedRecords);
  };

  async function sendRecordsToWebhook() {
    const sendingSnackbar = enqueueSnackbar("Sending...", {
      variant: "info",
    });

    try {
      const headerTitleElement = document.querySelector(
        ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
      )?.children[0];
      const headerTitle = headerTitleElement && headerTitleElement.innerText;
      const findedAction = priorityMassActions.find(
        (action) => action.screenName === headerTitle,
      );

      if (findedAction) {
        const webhook = findedAction.webhookUrl;
        const list = Object.keys(selectedRecordsListRef.current)
          .filter(
            (recordKey) => selectedRecordsListRef.current[recordKey]?.checked,
          )
          .map((recordKey) => selectedRecordsListRef.current[recordKey]?.value);

        closePriorityMassActionsScanner();

        const res = await axios.post(webhook, {
          data: list,
        });

        if (res?.status === 200 && res.data) {
          enqueueSnackbar("Success", {
            variant: "success",
          });
          closeSnackbar(sendingSnackbar);
        } else {
          enqueueSnackbar("Error", {
            variant: "error",
          });
          closeSnackbar(sendingSnackbar);
        }
      } else {
        enqueueSnackbar("Error", {
          variant: "error",
        });
        closeSnackbar(sendingSnackbar);
      }
    } catch (error) {
      console.error("sendRecordsToWebhook error: ", error);
      enqueueSnackbar("Error", {
        variant: "error",
      });
      closeSnackbar(sendingSnackbar);
    }
  }

  return (
    <React.Fragment>
      {appButtonsRef.current &&
        showPriorityActionsBtn &&
        createPortal(
          <Fab
            size="small"
            color="primary"
            sx={{
              width: 30,
              minWidth: 30,
              height: 30,
              minHeight: 30,
            }}
            onClick={() => {
              showPriorityActionsPopover
                ? closePriorityMassActionsScanner()
                : handleStartScanRecordsForWebhookAction();
            }}
            ref={priorityActionsBtnRef}
            // touchRippleRef={showPriorityBtnRippleRef}
          >
            {priorityBtnLoading ? (
              <CircularProgress size={20} color="customWhite" />
            ) : (
              <PlaylistAddCheck sx={{ color: "common.white", fontSize: 20 }} />
            )}
          </Fab>,
          appButtonsRef.current,
        )}

      <Popover
        open={showPriorityActionsPopover}
        anchorEl={priorityActionsBtnRef.current}
        onClose={() => {}}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        disableScrollLock
        slotProps={{
          paper: {
            sx: {
              pointerEvents: "auto",
              width: 475,
              // maxHeight: 400,
              overflow: "hidden",
            },
          },
        }}
        sx={{
          pointerEvents: "none",
          "& .MuiBackdrop-root": {
            pointerEvents: "none",
          },
        }}
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Typography fontSize={12} mb={1}>
            רשומות שנטענו: {priorityActionsRecordsList.length}
            <br />
            רשומות לשליחה:{" "}
            {
              Object.keys(selectedRecordsListRef.current).filter(
                (recordKey) =>
                  selectedRecordsListRef.current[recordKey]?.checked,
              ).length
            }
          </Typography>
          <Typography fontSize={12} mb={1.25} maxWidth={375}>
            הרשימה כוללת רשומות שמוצגות במסך בלבד.
            <br />
            כדי לטעון רשומות נוספות, יש לגלול במסך פריוריטי ולהציג רשומות
            נוספות.
            <br />
            רשומות שיוצגו במסך פריוריטי ייטענו לרשימה באופן אוטומטי. ניתן לגרוע
            רשומה שנטענה כדי שלא תשלח באמצעו הסרת סימון ה"וי".
          </Typography>

          <Box sx={{ flex: "1 1 auto", py: 0.5 }}>
            <Box sx={{ maxHeight: 325, overflow: "auto" }}>
              {priorityActionsRecordsList.length > 0 ? (
                [
                  <Grid
                    container
                    spacing={1}
                    alignItems={"center"}
                    key="table-header"
                  >
                    <Grid item xs={2}></Grid>
                    {Object.keys(priorityActionsRecordsList[0]).map(
                      (recordKey) => (
                        <Grid item xs>
                          <Typography fontSize={12} my={0.5} fontWeight={600}>
                            {recordKey}
                          </Typography>
                        </Grid>
                      ),
                    )}
                  </Grid>,
                  priorityActionsRecordsList.map((record) => {
                    const recordKey = record[Object.keys(record)[0]];
                    const isChecked =
                      selectedRecords[recordKey]?.checked || false;

                    return (
                      <Grid
                        container
                        spacing={1}
                        alignItems={"center"}
                        key={recordKey}
                      >
                        <Grid item xs={2}>
                          <Checkbox
                            aria-label=""
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(recordKey)}
                          />
                        </Grid>
                        {Object.keys(record).map((recordKey, index) => (
                          <Grid item xs>
                            <Typography
                              fontSize={12}
                              my={0.5}
                              fontWeight={index === 0 ? 600 : 400}
                            >
                              {record[recordKey]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    );
                  }),
                ]
              ) : (
                <Typography fontSize={12} my={0.5} fontWeight={600}>
                  No records yet
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
            <Button
              size="small"
              variant="contained"
              onClick={() => handlePriorityMassActionsRecordsList([])}
            >
              רענון נתונים
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => sendRecordsToWebhook()}
            >
              שליחה
            </Button>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default MassActionsDetector;
