/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import ChatHeader from "./ChatHeader/ChatHeader";
import {
  UseFetchGet,
  UseFetchPost,
  UseFetchPostForm,
} from "../../hooks/fetchFunctions";
import {
  GetFileTypeByLink,
  IsJsonString,
  generateUniqueId,
  getDateParts,
  getFileType,
} from "../../libs/helpers";
import { docx, uploadPreview, xlsx, zip } from "../../libs/images";
import ChatInputArea from "./ChatInputArea/ChatInputArea";
// import TemplatesMenu from "../Templates/TemplatesMenu";
import TemplatesMenu from "../TemplatesNew/TemplatesMenu";
import SingleMessageTemplate from "./SingleMessage/SingleMessageTemplate";
import SingleMessageReplyTemplate from "./SingleMessage/SingleMessageReplyTemplate";
import localizations from "../../localizations.json";
import Compressor from "compressorjs";
import FileUploadPreview from "./FileUploadPreview";
import { applicationType } from "../../applicationType";
import { sendMessageToBackground } from "../../hooks/extensionActions";
import MessagesContextMenu from "./MessagesContext/MessagesContextMenu";
import SendReplyTemplate from "./SendReplyTemplate/SendReplyTemplate";
import EmojiReplyTemplate from "./EmojiReplyTemplate";
import InfoMessageTemplate from "./SingleMessage/InfoMessageTemplate";
import {
  Alert,
  Badge,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Skeleton,
  Zoom,
} from "@mui/material";
import { Close, KeyboardArrowDown, OpenInNew } from "@mui/icons-material";
import "react-virtualized/styles.css";
import { sortListByParam } from "../../libs/messages";
import { enqueueSnackbar } from "notistack";
import ForwardChatUsersPopup from "./Actions/ForwardChatUsersPopup";
import ChatUserConversations from "./ChatHeader/ChatUserConversations/ChatUserConversations";
import axios from "axios";
import ForwardMessagesToWebhookPreview from "./Actions/ForwardMessagesToWebhookPreview";

let isMoreMessages = false;
let isLoadMoreMessages = false;
let taggedUsersNotificationIds = [];
let prevScrollTop = 0;
let scrollDirection = null;

const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  // mount
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        observerSettings,
      );

    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return [isSticky, ref, setIsSticky];
};

const ChatDateIndicator = ({ currentMessageDate, container }) => {
  const elemRef = useRef(null);
  const [isSticky, ref, setIsSticky] = useDetectSticky(elemRef, {
    threshold: [1],
    root: container,
  });

  const formatDate = (date) => {
    const today = new Date();
    const messageDate = new Date(date);

    if (today.toDateString() === messageDate.toDateString()) {
      return "היום";
    }

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    if (yesterday.toDateString() === messageDate.toDateString()) {
      return "אתמול";
    }

    const newDateStr = date;
    // return date?.replaceAll("-", "/") || "";
    return newDateStr?.split("-").reverse().join("/") || "";
    // return messageDate.toLocaleDateString("ru-RU", {
    //   day: "numeric",
    //   month: "long",
    //   year: "numeric",
    // });
  };

  // useEffect(() => {
  //   if (isSticky) {
  //     console.log("sticky ", elemRef.current);
  //   }
  // }, [isSticky]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        mt: 2.25,
        position: "sticky",
        top: -1,
        // top: 0,
        left: 0,
        zIndex: 100000,
      }}
      ref={elemRef}
      className={isSticky ? "sticky-date" : null}
      // ref={(el) => (el ? dateRefs.current.push(el) : null)}
    >
      {/* <Box
                            sx={{
                              display: "inline-flex",
                              width: "max-content",
                              bgcolor: "common.white",
                              borderRadius: 4,
                            }}
                          > */}
      <Chip
        label={formatDate(currentMessageDate)}
        sx={{
          backgroundColor: "#5a5a5a",
          color: "common.white",
          position: "relative",
          zIndex: 2,
          width: 90,
        }}
        size="small"
      />
      {/* </Box> */}
    </Box>
  );
};

const Chat = React.memo(
  ({
    chatType,
    selectedUserId,
    selectedChatUser,
    selectedUserRef,
    openChatByContact,
    chatUsersFunnerRef,
    chatUsersGlobalRef,
    userData,
    userDataRef,
    userConfig,
    chatLoading,
    setChatLoading,
    isViewsPage,
    funnerUsers,
    closeChat,
    handleSetNotif,
    chatUsersLabelsList,
    toggleUnreadMessagesPopup,
    updateChatUser,
    businessInfo,
    templatesList,
    templatesLoading,
    fetchWhatsappTemplates,
    connection,
    isConnection,
    tabActiveRef,
    handlePrioritySendDocsWindow,
    sendUploadPriority,
    resetSendUploadPriority,
    isNavOpen,
    chatUsersMessagesRef,
    containerStyle,
    chatFolded,
    isHashtag,
    handleChatUserMessages,
    clearChatUsersMessages,
    disableChatGoBack,
    textareaRef,
    getChatMissingHistory,
    chatUsersGlobal,
    goToFavouriteMessageState,
    goToFavouriteMessageStateRef,
    handleGoToFavouriteMessageState,
  }) => {
    const LOAD_MESSAGES_COUNT = 20;
    const LOAD_MORE_MESSAGES_COUNT = 100;

    // console.log("chat render");

    // useEffect(() => {
    //   console.log("chatType rendered");
    // }, [chatType]);

    // useEffect(() => {
    //   console.log("selectedUserId rendered");
    // }, [selectedUserId]);

    // useEffect(() => {
    //   console.log("selectedChatUser rendered");
    // }, [selectedChatUser]);

    // useEffect(() => {
    //   console.log("selectedUserRef rendered");
    // }, [selectedUserRef]);

    // useEffect(() => {
    //   console.log("onUserCardClick rendered");
    // }, [onUserCardClick]);

    // useEffect(() => {
    //   console.log("chatUsersFunnerRef rendered");
    // }, [chatUsersFunnerRef]);

    // useEffect(() => {
    //   console.log("chatUsersGlobalRef rendered");
    // }, [chatUsersGlobalRef]);

    // useEffect(() => {
    //   console.log("userData rendered");
    // }, [userData]);

    // useEffect(() => {
    //   console.log("userDataRef rendered");
    // }, [userDataRef]);

    // useEffect(() => {
    //   console.log("userConfig rendered");
    // }, [userConfig]);

    // useEffect(() => {
    //   console.log("chatLoading rendered");
    // }, [chatLoading]);

    // useEffect(() => {
    //   console.log("setChatLoading rendered");
    // }, [setChatLoading]);

    // useEffect(() => {
    //   console.log("isViewsPage rendered");
    // }, [isViewsPage]);

    // useEffect(() => {
    //   console.log("funnerUsers rendered");
    // }, [funnerUsers]);

    // useEffect(() => {
    //   console.log("closeChat rendered");
    // }, [closeChat]);

    // useEffect(() => {
    //   console.log("handleSetNotif rendered");
    // }, [handleSetNotif]);

    // useEffect(() => {
    //   console.log("chatUsersLabelsList rendered");
    // }, [chatUsersLabelsList]);

    // useEffect(() => {
    //   console.log("toggleUnreadMessagesPopup rendered");
    // }, [toggleUnreadMessagesPopup]);

    // useEffect(() => {
    //   console.log("updateChatUser rendered");
    // }, [updateChatUser]);

    // useEffect(() => {
    //   console.log("businessInfo rendered");
    // }, [businessInfo]);

    // useEffect(() => {
    //   console.log("templatesList rendered");
    // }, [templatesList]);

    // useEffect(() => {
    //   console.log("templatesLoading rendered");
    // }, [templatesLoading]);

    // useEffect(() => {
    //   console.log("fetchWhatsappTemplates rendered");
    // }, [fetchWhatsappTemplates]);

    // useEffect(() => {
    //   console.log("connection rendered");
    // }, [connection]);

    // useEffect(() => {
    //   console.log("isConnection rendered");
    // }, [isConnection]);

    // useEffect(() => {
    //   console.log("tabActiveRef rendered");
    // }, [tabActiveRef]);

    // useEffect(() => {
    //   console.log("handlePrioritySendDocsWindow rendered");
    // }, [handlePrioritySendDocsWindow]);

    // useEffect(() => {
    //   console.log("sendUploadPriority rendered");
    // }, [sendUploadPriority]);

    // useEffect(() => {
    //   console.log("resetSendUploadPriority rendered");
    // }, [resetSendUploadPriority]);

    // useEffect(() => {
    //   console.log("isNavOpen rendered");
    // }, [isNavOpen]);

    // useEffect(() => {
    //   console.log("chatUsersMessagesRef rendered");
    // }, [chatUsersMessagesRef]);

    // useEffect(() => {
    //   console.log("containerStyle rendered");
    // }, [containerStyle]);

    // useEffect(() => {
    //   console.log("chatFolded rendered");
    // }, [chatFolded]);

    // useEffect(() => {
    //   console.log("isHashtag rendered");
    // }, [isHashtag]);

    // useEffect(() => {
    //   console.log("handleChatUserMessages rendered");
    // }, [handleChatUserMessages]);

    // useEffect(() => {
    //   console.log("clearChatUsersMessages rendered");
    // }, [clearChatUsersMessages]);

    // useEffect(() => {
    //   console.log("disableChatGoBack rendered");
    // }, [disableChatGoBack]);

    // useEffect(() => {
    //   console.log("textareaRef rendered");
    // }, [textareaRef]);

    // useEffect(() => {
    //   console.log("getChatMissingHistory rendered");
    // }, [getChatMissingHistory]);

    // useEffect(() => {
    //   console.log("chatUsersGlobal rendered");
    // }, [chatUsersGlobal]);

    // useEffect(() => {
    //   console.log("goToFavouriteMessageState rendered");
    // }, [goToFavouriteMessageState]);

    // useEffect(() => {
    //   console.log("goToFavouriteMessageStateRef rendered");
    // }, [goToFavouriteMessageStateRef]);

    // useEffect(() => {
    //   console.log("handleGoToFavouriteMessageState rendered");
    // }, [handleGoToFavouriteMessageState]);

    const isButtonsPopupForForwardMessagesWebhook =
      businessInfo.CompanyId === "987a2eaa-4d89-43e5-8e58-c7006513b9f4";
    // businessInfo.CompanyId === "a226c4d1-72de-4743-b332-6b70cd22fec2";

    //! States and views
    const [isTemplatesAccordionOpen, setIsTemplatesAccordionOpen] =
      useState(false);
    const [isTemplateSending, setIsTemplateSending] = useState(false);

    const [selectedChatUserConfig, setSelectedChatUserConfig] = useState(null);
    const [sendToPlatformsStatus, setSendToPlatformsStatus] =
      useState("whatsapp");
    const [isMessageSending, setIsMessageSending] = useState(false);
    // const [textareaValue, setTextareaValue] = useState("");
    const [messages, setMessages] = useState([]);
    const [filteredSearchMessages, setFilteredSearchMessages] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [previousSearchQuery, setPreviousSearchQuery] = useState("");
    const [searchMessagesActive, setSearchMessagesActive] = useState(false);
    const [isGoBotBtn, setIsGoBotBtn] = useState(false);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [isSmileBottomPanelOpen, setIsSmileBottomPanelOpen] = useState(false);

    const [sendUpload, setSendUpload] = useState({
      isUpload: false,
      file: "",
      isVideo: false,
      fileTypeImg: null,
    });
    const [sendReply, setSendReply] = useState({
      isReply: false,
      message: null,
      userName: null,
    });
    const [messagesContextState, setMessagesContextState] = useState({
      message: null,
      anchor: null,
    });
    const [sendEmojiReply, setSendEmojiReply] = useState({
      visible: false,
      coords: null,
      message: null,
    });
    const [mediaPopupView, setMediaPopupView] = useState({
      visible: false,
      file: null,
      type: null,
    });
    const [forwardMessage, setForwardMessage] = useState({
      visible: false,
      messagesList: [],
      chatUsersPopup: false,
      chatUsersPhoneList: [],
    });
    const [forwardMessagesWebhookList, setForwardMessagesWebhookList] =
      useState(null);
    const [forwardMessagesWebhookPreview, setforwardMessagesWebhookPreview] =
      useState(false);
    const [
      forwardMessagesWebhookSelectable,
      setForwardMessagesWebhookSelectable,
    ] = useState(false);
    const [resetTemplateState, setResetTemplateState] = useState(null);
    const [chatCustomizationSettings, setChatCustomizationSettings] =
      useState(null);
    const [noMoreMessages, setNoMoreMessages] = useState(false);

    const [chatUserConversations, setChatUserConversations] = useState([]);
    const [chatUserConversationsAnchor, setChatUserConversationsAnchor] =
      useState(null);
    const [chatUserConversationsLoading, setChatUserConversationsLoading] =
      useState(false);

    const forwardMessagesWebhook = useMemo(() => {
      let webhook = null,
        status = false;
      if (IsJsonString(businessInfo?.CompanyPlatforms)) {
        const platformsObject = JSON.parse(businessInfo.CompanyPlatforms);
        const webHooksList = platformsObject.webHooks;
        if (webHooksList) {
          const findedForwardMessagesWebhook = webHooksList.find(
            (webhook) => webhook.WebhookIdentifier === "forwardMessagesWebhook",
          );
          if (findedForwardMessagesWebhook) {
            webhook = findedForwardMessagesWebhook.WebhookUrl;
            const webhookActiveStates = platformsObject.webHooksActiveStates;
            status = webhookActiveStates?.forwardMessagesWebhook ?? false;
          }
        }
      }
      return { url: webhook, status };
    }, [businessInfo?.CompanyPlatforms]);

    //! Refs
    // const selectedUserRef = useRef(null);
    const selectedChatUserConfigRef = useRef(null);
    const messagesFeedRef = useRef(null);
    // const textareaRef = useRef(null);
    const sendToPlatformsStatusRef = useRef(null);
    // const messagesPaginationRef = useRef({
    //   top: 1,
    //   bottom: 1,
    // });
    const dateRefs = useRef([]);
    const [activeDate, setActiveDate] = useState(null);

    const videosLoading = useRef([]);
    const userChangedControllerRef = useRef(null);
    const messagesListRef = useRef([]);
    const messagesContextRef = useRef(null);
    const emojiReplyRef = useRef(null);
    const searchMessagesArr = useRef(null);
    const searchQueryRef = useRef("");
    const searchTimeoutId = useRef(null);
    const isGoBotBtnRef = useRef(false);
    const lastCallIdRef = useRef(0);
    // const dateRefs = useRef([]);

    //! Functions and handlers
    function switchToChatType(type) {
      setSendToPlatformsStatus(type);
      sendToPlatformsStatusRef.current = type;
    }
    const toggleAccordion = () => {
      const newValue = !isTemplatesAccordionOpen;
      setSearchMessagesActive(false);
      setIsTemplatesAccordionOpen(newValue);
      if (newValue) {
        fetchWhatsappTemplates();
      }
    };
    const closeAccordion = () => {
      setIsTemplatesAccordionOpen(false);
      resetTemplateFromChat();
    };
    function scrollToLastMessage(makeUnread = false) {
      messagesFeedRef.current.scrollTop =
        messagesFeedRef.current.scrollHeight * 2;

      if (selectedUserRef.current.UnreadMessagesNumber > 0 && makeUnread) {
        setTimeout(() => {
          makeChatUserRead(selectedUserRef.current.UserDisplayPhoneNumber);
        }, 200);
      }
    }
    function handleMessagesList(list) {
      setMessages(list);
      messagesListRef.current = list;
    }
    const scrollToMessage = (id) => {
      if (!id) return;
      const parentScroll = messagesFeedRef.current;
      const message = document.getElementById("funner-global-chat-msg-" + id);
      console.log(message);

      if (message)
        parentScroll.scrollTo(
          0,
          message.offsetTop -
            (parentScroll.offsetHeight - message.offsetHeight - 20),
        );
    };
    const saveUserMessagesToState = (messages, phoneNumber) => {
      handleChatUserMessages(phoneNumber, {
        messages: [...messages],
      });
    };
    const makeChatUserRead = async (phone) => {
      try {
        updateChatUser(phone, {
          UnreadMessagesNumber: 0,
        });

        const res = await UseFetchGet(
          "/api/services/setChatUserUnread",
          {
            chatUserPhoneNumberId: phone,
          },
          null,
          undefined,
          "history",
        );

        console.log(res);
      } catch (error) {}
    };
    const updateSingleMessage = (id, params) => {
      const newMessagesList = messages.map((msg) => {
        let newMsg = { ...msg };
        if (msg.Id === id) {
          newMsg = { ...msg, ...params };
        }
        return newMsg;
      });
      handleMessagesList(newMessagesList);
      saveUserMessagesToState(
        [...newMessagesList],
        selectedUserRef.current.UserDisplayPhoneNumber,
      );
    };

    //! Forward messages to webhook
    const handleMessagesToWebhookPopup = (state) => {
      setforwardMessagesWebhookPreview(state);
    };
    const sendSingleMessageToWebhook = (message) => {
      const newList = [message];
      if (isButtonsPopupForForwardMessagesWebhook) {
        setForwardMessagesWebhookList(newList);
        handleMessagesToWebhookPopup(true);
      } else {
        forwardMessagesToWebhook(newList);
      }
    };
    const toggleMessageInWebhookList = (messageId) => {
      let currentList = forwardMessagesWebhookList || [];
      if (currentList.some((msg) => msg.Id === messageId)) {
        setForwardMessagesWebhookList(
          currentList.filter((msg) => msg.Id !== messageId),
        );
      } else {
        const targetMessage = messages.find((msg) => msg.Id === messageId);
        setForwardMessagesWebhookList([...currentList, targetMessage]);
      }
    };
    const sendAllMessagesToWebhook = async () => {
      try {
        const newMessages = await getChatMessages(
          1,
          selectedChatUser.UserDisplayPhoneNumber,
          1000,
        );

        if (isButtonsPopupForForwardMessagesWebhook) {
          handleMessagesToWebhookPopup(true);
          setForwardMessagesWebhookList(newMessages.messages);
        } else {
          forwardMessagesToWebhook(newMessages.messages);
        }
      } catch (error) {
        console.error("sendAllMessagesToWebhook error: ", error);
        enqueueSnackbar("Error while getting messages", { variant: "error" });
      }
    };
    const forwardMessagesToWebhook = async (
      messages,
      additionalParams = {},
    ) => {
      try {
        resetForwardMessagesToWebhook();
        if (!forwardMessagesWebhook.url)
          return enqueueSnackbar("No url set to webhook", {
            variant: "error",
          });

        const params = {
          conversation: messages,
          represetativePhoneNumber: userDataRef.current?.PhoneNumber || "",
          ...additionalParams,
        };

        const res = await axios.post(forwardMessagesWebhook.url, params);
        if (res?.status === 200) {
          enqueueSnackbar("Successfully sent", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Some error occured", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("forwardMessagesToWebhook error: ", error);
        enqueueSnackbar(error.message, { variant: "error" });
      }
    };
    const resetForwardMessagesToWebhook = () => {
      setForwardMessagesWebhookList(null);
      setForwardMessagesWebhookSelectable(false);
    };

    //! Search handlers
    const handleSearchInput = (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      searchQueryRef.current = value;
      handleSearchQuery(value);
    };
    const handleSearchQuery = (value) => {
      clearTimeout(searchTimeoutId.current);

      if (!searchMessagesArr.current) {
        searchTimeoutId.current = setTimeout(() => {
          if (value.trim() !== previousSearchQuery.trim()) {
            search();
          }
        }, 1200);
      } else search();

      async function search() {
        setChatLoading(true);
        if (value.length && !searchMessagesArr.current) {
          await getChatSearchMessagesArr();
        }
        console.log(`Searching for: ${value}`);
        setPreviousSearchQuery(value);

        setSearchMessagesActive(Boolean(value.length));
        if (value.length) {
          const newArr = searchMessagesArr.current
            .map((msg, index) => ({ msg, index }))
            .filter(
              (message) =>
                message.msg.Message?.toLowerCase().includes(
                  value.toLowerCase(),
                ) && message.msg.FileType !== "info",
            );
          setFilteredSearchMessages(newArr);
        } else {
          setTimeout(() => {
            setFilteredSearchMessages([]);
          }, 300);
        }
        setChatLoading(false);
      }
    };
    const clearSearch = async () => {
      setSearchQuery("");
      searchQueryRef.current = "";
      setPreviousSearchQuery("");
      setSearchMessagesActive(false);
      // searchMessagesArr.current = null;
      setFilteredSearchMessages([]);
    };
    const onSearchMessageClick = async (index, id) => {
      try {
        // isMoreMessages = false;
        setChatLoading(true);
        // const newPaginationNum = Math.floor(index / LOAD_MESSAGES_COUNT) + 1;
        const newPaginationNum =
          Math.floor(index / LOAD_MORE_MESSAGES_COUNT) + 1;
        // handleMessagesList([]);
        clearSearch();
        const searchMessageHistory = await getChatMessages(
          newPaginationNum,
          null,
          LOAD_MORE_MESSAGES_COUNT,
        );
        setIsActive(
          searchMessageHistory.isActive !== null
            ? searchMessageHistory.isActive
            : isActive,
        );

        const newList = sortListByParam(
          [
            ...messages.filter(
              (message) =>
                !searchMessageHistory.messages.some(
                  (msg) => msg.Id === message.Id,
                ),
            ),
            {
              FileType: "buffer",
              messageFromId:
                searchMessageHistory.messages[
                  searchMessageHistory.messages.length - 1
                ].Id,
              direction: "top",
              Id:
                searchMessageHistory.messages[
                  searchMessageHistory.messages.length - 1
                ].Id - 0.9,
              action: "search",
            },
            {
              Id: searchMessageHistory.messages[0].Id + 0.1,
              FileType: "buffer",
              messageFromId: searchMessageHistory.messages[0].Id,
              direction: "bottom",
              action: "search",
            },
            ...searchMessageHistory.messages,
          ],
          "Id",
        );
        handleMessagesList(newList);
        //! Save messages to state
        saveUserMessagesToState(
          [...newList],
          selectedUserRef.current.UserDisplayPhoneNumber,
        );
      } catch (error) {
        console.log("onSearchMessageClick err: ", error);
      } finally {
        setTimeout(() => {
          // const scrollToIndex = newList.findIndex((msg) => msg.Id === id);
          scrollToMessage(id);
          setChatLoading(false);
          // setTimeout(() => {
          //   isMoreMessages = true;
          // }, 300);
        }, 100);
      }
    };

    const handleSendUploadState = (params) => {
      setSendUpload((prevObj) => {
        return { ...prevObj, ...params };
      });
    };
    const resetSendUpload = () => {
      handleSendUploadState({
        isUpload: false,
        file: "",
        isVideo: false,
        fileTypeImg: null,
      });
    };
    function compressImage(file, callback) {
      new Compressor(file, {
        quality: 0.6, // compress level
        maxWidth: 1000,
        maxHeight: 1200,
        success(result) {
          callback(result);
        },
        error(err) {
          console.error("Compressing error: ", err.message);
        },
      });
    }

    const handleSendReply = (params) => {
      setSendReply((prevObj) => {
        return { ...prevObj, ...params };
      });
    };
    const resetSendReply = () => {
      handleSendReply({
        isReply: false,
        message: null,
        userName: null,
      });
    };

    const handleMessagesContextState = (params) => {
      setMessagesContextState((prevObj) => {
        return { ...prevObj, ...params };
      });
    };
    const closeMessagesContext = () => {
      handleMessagesContextState({
        message: null,
        anchor: null,
      });
    };
    const onMessagesContext = (e, message) => {
      e.preventDefault();
      // const targetMessage = e.target.closest(".message");
      // const { left, top } = targetMessage.getBoundingClientRect();
      // const parentWidth = targetMessage.parentElement.offsetWidth;
      // const contextWidth = 210;

      // const x =
      //   parentWidth - (e.clientX + contextWidth) < 20
      //     ? parentWidth - contextWidth - 20
      //     : e.clientX < left + 75
      //     ? left + 75 - targetMessage.offsetWidth / 2
      //     : e.clientX - targetMessage.offsetWidth / 2;
      // const y = e.clientY - top + targetMessage.parentElement.offsetTop;

      const targetMessage = e.currentTarget;

      if (messagesContextState.anchor) {
        closeMessagesContext();
      } else {
        handleMessagesContextState({
          // visible: true,
          // coords: {
          //   x: x,
          //   y: y,
          // },
          message: message,
          anchor: targetMessage,
          mouseX: e.clientX + 80,
          mouseY: e.clientY + 2,
        });
      }
    };

    const handleSendEmojiReply = (params) => {
      setSendEmojiReply((prevObj) => {
        return { ...prevObj, ...params };
      });
    };
    const resetSendEmojiReply = () => {
      handleSendEmojiReply({
        visible: false,
        coords: null,
        message: null,
      });
    };
    const onEmojiReplyIconClicked = (e, message) => {
      resetSendEmojiReply();

      setTimeout(() => {
        e.preventDefault();
        const targetBtn =
          e.target.closest(".smile-to-react") ||
          e.target.closest(".reaction-emoji");
        const parentElement = targetBtn.closest(".message-wrap");
        const parentWidth = parentElement.offsetWidth;
        const replyPopupWidth = 260;

        const { left } = targetBtn
          .closest(".message-wrap")
          .querySelector(".message")
          .getBoundingClientRect();

        const x =
          parentWidth - (e.clientX + replyPopupWidth) < 20
            ? parentWidth - replyPopupWidth - 20
            : e.clientX < left + 75
            ? left + 75 - replyPopupWidth / 2
            : e.clientX - replyPopupWidth / 2;
        const y = targetBtn.offsetTop + parentElement.offsetTop;

        handleSendEmojiReply({
          visible: true,
          coords: {
            x: x,
            y: y,
          },
          message: message,
        });
      }, 0);
    };

    const handleMediaPreview = (params) => {
      setMediaPopupView((prevObj) => {
        return { ...prevObj, ...params };
      });
    };
    const closePreviewPopup = () => {
      handleMediaPreview({ visible: false });

      setTimeout(() => {
        handleMediaPreview({ file: null, type: null });
      }, 300);
    };

    const resetTemplateFromChat = () => {
      setResetTemplateState(Math.random());
    };

    //! Forward message handlers
    const handleForwardMessage = (params) => {
      setForwardMessage((prevObj) => ({ ...prevObj, ...params }));
    };
    const onForwardContextClick = (message) => {
      handleForwardMessage({
        visible: true,
        messagesList: [message.Id],
      });
      setIsSmileBottomPanelOpen(false);
      resetSendUpload();
      resetSendReply();
      resetSendEmojiReply();
      resetSendUploadPriority();
      setForwardMessagesWebhookList(null);
      setForwardMessagesWebhookSelectable(false);
    };
    const toggleMessageInForwardList = (messageId) => {
      console.log("toggleMessageInForwardList called: ", messageId);
      console.log(
        "toggleMessageInForwardList called: ",
        forwardMessage.messagesList,
      );
      const messagesListCopy = [...forwardMessage.messagesList];
      if (messagesListCopy.includes(messageId)) {
        const msgIndexInList = messagesListCopy.indexOf(messageId);
        messagesListCopy.splice(msgIndexInList, 1);
      } else {
        messagesListCopy.push(messageId);
      }
      handleForwardMessage({
        messagesList: messagesListCopy,
      });
    };
    const toggleChatUsersInForwardList = (userPhone) => {
      const chatUsersListCopy = [...forwardMessage.chatUsersPhoneList];
      if (chatUsersListCopy.includes(userPhone)) {
        const msgIndexInList = chatUsersListCopy.indexOf(userPhone);
        chatUsersListCopy.splice(msgIndexInList, 1);
      } else {
        chatUsersListCopy.push(userPhone);
      }
      handleForwardMessage({
        chatUsersPhoneList: chatUsersListCopy,
      });
    };
    const closeForwardMessage = () => {
      setForwardMessage({
        visible: false,
        messagesList: [],
        chatUsersPopup: false,
        chatUsersPhoneList: [],
      });
    };
    const closeForwardChatUsersPopup = () => {
      handleForwardMessage({
        chatUsersPopup: false,
        chatUsersPhoneList: [],
      });
    };
    async function handleSendForwardMessages(forwardObject) {
      closeForwardMessage();
      try {
        const params = {
          messagesList: forwardObject.messagesList,
          chatUsersPhoneList: forwardObject.chatUsersPhoneList,
        };

        const res = await UseFetchPost(
          "/api/services/forwardMessage",
          params,
          "messages",
        );
        if (!res?.data?.result) {
          enqueueSnackbar(
            localizations["he"].chat.messages.forwardMessages.sendError,
            {
              variant: "error",
            },
          );
        }
      } catch (error) {
        console.log(
          "handleSendForwardMessages error: ",
          handleSendForwardMessages,
        );
        enqueueSnackbar(
          localizations["he"].chat.messages.forwardMessages.sendError,
          {
            variant: "error",
          },
        );
      }
    }

    async function updateMessageFavourite(id, state) {
      try {
        const res = await UseFetchPost(
          "/api/massMessaging/updateMessageFavouriteStatus",
          {
            id,
            isFavourite: state,
          },
          "history",
        );
        console.log(res);
        if (res?.data?.result) {
          updateSingleMessage(id, { isFavourite: state });
        } else {
          enqueueSnackbar("Some error, please try again", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Some error, please try again", {
          variant: "error",
        });
        console.error("updateMessageFavourite error: ", error);
      }
    }

    //! Async and fetch functions
    async function getChatUserConfig(whatsappPhoneNumber) {
      try {
        const res = await UseFetchGet(
          "/api/services/getChatUserConfig",
          {
            chatUserPhoneId: whatsappPhoneNumber,
          },
          userChangedControllerRef.current,
          undefined,
          "config",
        );

        if (res.data.result) {
          let resultConfig = IsJsonString(res.data.configObj)
            ? JSON.parse(res.data.configObj)
            : res.data.configObj;

          selectedChatUserConfigRef.current = resultConfig;
          setSelectedChatUserConfig(resultConfig);
          return resultConfig;
        } else {
          selectedChatUserConfigRef.current = null;
          setSelectedChatUserConfig(null);
          return null;
        }
      } catch (err) {
        console.log("getChatUserConfig error: ", err);
        selectedChatUserConfigRef.current = null;
        setSelectedChatUserConfig(null);
        return null;
      }
    }
    async function handleChatUserConfig(
      whatsappPhoneNumber,
      params,
      isNotif = true,
      isSave = true,
    ) {
      if (!whatsappPhoneNumber) return false;
      if (
        whatsappPhoneNumber === selectedUserRef.current?.UserDisplayPhoneNumber
      ) {
        const currentConfig = {
          ...selectedChatUserConfigRef.current,
          ...params,
        };
        // currentConfig[key] = value;

        selectedChatUserConfigRef.current = currentConfig;
        setSelectedChatUserConfig(currentConfig);
        if (isSave) {
          const saveResult = saveChatUserConfig(
            selectedUserRef.current.UserDisplayPhoneNumber,
            currentConfig,
            isNotif,
          );

          return saveResult;
        }
        return true;
      } else {
        if (isSave) {
          const currentConfig = {
            ...(await getChatUserConfig(whatsappPhoneNumber)),
            ...params,
          };
          // currentConfig[key] = value;

          const saveResult = saveChatUserConfig(
            whatsappPhoneNumber,
            currentConfig,
            isNotif,
          );

          return saveResult;
        }
        return true;
      }
    }
    async function saveChatUserConfig(
      whatsappPhoneNumber,
      configObject,
      isNotif = true,
    ) {
      try {
        const res = await UseFetchPost(
          "/api/services/saveChatUserConfig",
          {
            chatUserPhoneId: whatsappPhoneNumber,
            configJsonString: JSON.stringify(configObject),
          },
          "config",
        );

        if (res.data.result) {
          if (isNotif)
            handleSetNotif(
              localizations["he"].chat.notifications.saveChatUserConfig.success,
              "success",
            );
          return true;
        } else {
          if (isNotif)
            handleSetNotif(
              localizations["he"].chat.notifications.saveChatUserConfig.error,
              "error",
            );
          return false;
        }
      } catch (err) {
        console.log("saveChatUserConfig error:", err);
        if (isNotif)
          handleSetNotif(
            localizations["he"].chat.notifications.saveChatUserConfig.error,
            "error",
          );
        return false;
      }
    }
    async function getChatMessages(
      pagination,
      phoneNumber = null,
      count = null,
    ) {
      // setMessagesLoading(true);
      try {
        const res = await UseFetchGet(
          `/api/whatsapp/getChatUserMsgHistoryActvStatus/${
            phoneNumber
              ? phoneNumber
              : selectedUserRef.current.UserDisplayPhoneNumber
          }/${pagination}/${count || LOAD_MESSAGES_COUNT}`,
          undefined,
          null,
          true,
          "history",
        );

        if (res?.data) {
          const isActive = res.data.isActive;
          const messagesList = [...res.data.messages];

          return {
            isActive: isActive,
            messages: messagesList,
            result: true,
          };
        } else {
          return {
            isActive: null,
            messages: [],
            result: false,
          };
        }
      } catch (error) {
        console.log("getChatUserMsgHistoryActvStatus error: ", error);
        return {
          isActive: null,
          messages: [],
          result: false,
        };
      } finally {
        // setMessagesLoading(false);
      }
    }
    async function getChatMessagesFromId(
      id,
      type,
      count = null,
      phoneNumber = null,
    ) {
      // setMessagesLoading(true);
      try {
        const res = await UseFetchGet(
          `/api/whatsapp/getChatUserMsgHistoryActvStatusByMessageId/${
            phoneNumber || selectedUserRef.current.UserDisplayPhoneNumber
          }/${id}/${count || LOAD_MESSAGES_COUNT}/${type}`,
          undefined,
          null,
          true,
          "history",
        );

        if (res?.data) {
          const isActive = res.data.isActive;
          const messagesList = [...res.data.messages];

          return {
            isActive: isActive,
            messages: messagesList ? messagesList : [],
            result: true,
          };
        } else {
          return {
            isActive: null,
            messages: [],
            result: false,
          };
        }
      } catch (error) {
        console.log("getChatUserMsgHistoryActvStatus error: ", error);
        return {
          isActive: null,
          messages: [],
          result: false,
        };
      } finally {
        // setMessagesLoading(false);
      }
    }
    async function getChatSearchMessagesArr() {
      try {
        const res = await UseFetchGet(
          "/api/whatsapp/searchChatUserWhatsappMessage",
          {
            phoneNumberId:
              // "0" + selectedUserRef.current.UserDisplayPhoneNumber.slice(3),
              selectedUserRef.current.UserDisplayPhoneNumber,
          },
          null,
          true,
          "history",
        );
        console.log(res);

        if (res?.status === 200) {
          searchMessagesArr.current = [...res.data];
        }
      } catch (error) {
        console.log("searchChatUserWhatsappMessage error: ", error);
      }
    }

    async function loadMoreMessagesOnTrigger(entry) {
      if (!isMoreMessages || !isLoadMoreMessages) return;
      try {
        const id = Number(
          entry.target.id.replace(
            chatType === "fireberry" ? "fireberry-buffer-" : "funner-buffer-",
            "",
          ),
        );
        const currentPhoneNumber =
          selectedUserRef.current.UserDisplayPhoneNumber;

        const bufferMessage = messages.find((msg) => msg.Id === id);

        if (bufferMessage.direction === "top" && scrollDirection === "top") {
          // if (bufferMessage.action === "search") {
          //   setChatLoading(true);
          //   feedWrapper.classList.add("no-scroll");
          //   feedWrapper.style.paddingLeft = "17px";
          // }

          isMoreMessages = false;
          console.log("scroll top buffer reached");

          const history = await getChatMessagesFromId(
            bufferMessage.messageFromId,
            "older",
            LOAD_MORE_MESSAGES_COUNT,
            currentPhoneNumber,
          );
          setIsActive(history.isActive !== null ? history.isActive : isActive);
          if (history?.messages?.length > 0) {
            const bufferIndex = messages.findIndex(
              (msg) => msg.Id === bufferMessage.Id,
            );

            const newMessages = history.messages;
            const newMessagesList = sortListByParam(
              [
                ...messages
                  .slice(0, bufferIndex)
                  .filter(
                    (message) =>
                      !newMessages.some((msg) => msg.Id === message.Id),
                  ),
                {
                  FileType: "buffer",
                  messageFromId: newMessages[newMessages.length - 1].Id,
                  direction: "top",
                  Id: newMessages[newMessages.length - 1].Id - 0.9,
                  action: bufferMessage.action,
                },
                ...newMessages,
                ...messages
                  .slice(bufferIndex + 1)
                  .filter(
                    (message) =>
                      !newMessages.some((msg) => msg.Id === message.Id),
                  ),
              ],
              "Id",
            );
            if (
              currentPhoneNumber ===
              selectedUserRef.current.UserDisplayPhoneNumber
            ) {
              if (messagesFeedRef.current?.scrollTop === 0)
                messagesFeedRef.current.scrollTop = 1;
              handleMessagesList(newMessagesList);
            }

            saveUserMessagesToState([...newMessagesList], currentPhoneNumber);
            setNoMoreMessages(false);
          } else setNoMoreMessages(true);
          // else
          // enqueueSnackbar(
          //   localizations["he"].chat.notifications.noMoreMessages,
          //   {
          //     variant: "warning",
          //   },
          // );

          // if (bufferMessage.action === "search") {
          //   setTimeout(() => {
          //     setChatLoading(false);
          //     feedWrapper.style.removeProperty("padding-left");
          //     feedWrapper.classList.remove("no-scroll");

          //     // setTimeout(() => {
          //     //   const newScrollHeight = feedWrapper.scrollHeight;
          //     //   console.log(newScrollHeight);
          //     //   const scrollTop = feedWrapper.scrollTop;
          //     //   const scrollHeightDifference = newScrollHeight - prevHeight;
          //     //   console.log(scrollHeightDifference);
          //     //   feedWrapper.scrollTop = scrollTop + scrollHeightDifference;
          //     // }, 0);
          //   }, 100);
          // }

          setTimeout(() => {
            //! if (history.messages.length >= LOAD_MORE_MESSAGES_COUNT)
            isMoreMessages = true;
          }, 500);
        } else if (
          bufferMessage.direction === "bottom" &&
          scrollDirection === "bottom"
        ) {
          // if (bufferMessage === "search") {
          //   setChatLoading(true);
          //   feedWrapper.classList.add("no-scroll");
          //   feedWrapper.style.paddingLeft = "17px";
          // }

          isMoreMessages = false;
          console.log("scroll bottom buffer reached");

          const history = await getChatMessagesFromId(
            bufferMessage.messageFromId,
            "newer",
            LOAD_MORE_MESSAGES_COUNT,
            currentPhoneNumber,
          );
          setIsActive((prevActive) =>
            history.isActive !== null ? history.isActive : prevActive,
          );
          if (history?.messages?.length > 0) {
            const bufferIndex = messages.findIndex(
              (msg) => msg.Id === bufferMessage.Id,
            );

            const newMessages = history.messages;
            const newMessagesList = sortListByParam(
              [
                ...messages
                  .slice(0, bufferIndex)
                  .filter(
                    (message) =>
                      !newMessages.some((msg) => msg.Id === message.Id),
                  ),
                ...newMessages,
                {
                  FileType: "buffer",
                  messageFromId: newMessages[newMessages.length - 1].Id,
                  direction: "bottom",
                  Id: newMessages[newMessages.length - 1].Id + 0.1,
                  action: bufferMessage.action,
                },
                ...messages
                  .slice(bufferIndex + 1)
                  .filter(
                    (message) =>
                      !newMessages.some((msg) => msg.Id === message.Id),
                  ),
              ],
              "Id",
            );

            if (
              currentPhoneNumber ===
              selectedUserRef.current.UserDisplayPhoneNumber
            ) {
              if (messagesFeedRef.current?.scrollTop === 0)
                messagesFeedRef.current.scrollTop = 1;
              handleMessagesList(newMessagesList);
            }
            saveUserMessagesToState([...newMessagesList], currentPhoneNumber);
            setNoMoreMessages(false);
          } else setNoMoreMessages(true);
          // else
          // enqueueSnackbar(
          //   localizations["he"].chat.notifications.noMoreMessages,
          //   {
          //     variant: "warning",
          //   },
          // );

          // if (bufferMessage.action === "search") {
          //   setTimeout(() => {
          //     setChatLoading(false);
          //     feedWrapper.style.removeProperty("padding-left");
          //     feedWrapper.classList.remove("no-scroll");
          //     // setTimeout(() => {
          //     //   const newScrollHeight = feedWrapper.scrollHeight;
          //     //   console.log(newScrollHeight);
          //     //   const scrollTop = feedWrapper.scrollTop;
          //     //   const scrollHeightDifference = newScrollHeight - prevHeight;
          //     //   console.log(scrollHeightDifference);
          //     //   feedWrapper.scrollTop = scrollTop + scrollHeightDifference;
          //     // }, 0);
          //   }, 100);
          // }

          setTimeout(() => {
            //! if (history.messages.length >= LOAD_MORE_MESSAGES_COUNT)
            isMoreMessages = true;
          }, 500);
        }
      } catch (error) {
        console.log("loadMoreMessagesOnTrigger err: ", error);
        setTimeout(() => {
          isMoreMessages = true;
        }, 300);
      }
    }
    async function loadMoreMessagesOnLoad(
      phoneNumber,
      messages,
      currentCallId,
    ) {
      try {
        isLoadMoreMessages = false;
        const filteredCurrentMessages = messages.filter(
          (msg) => msg.FileType !== "buffer",
        );

        let targetMessage = filteredCurrentMessages[0];

        const moreMessagesRes = await getChatMessagesFromId(
          targetMessage.Id,
          "older",
          LOAD_MORE_MESSAGES_COUNT,
          phoneNumber,
        );

        if (moreMessagesRes.result) {
          let filteredNewMessages = sortListByParam(
            [...moreMessagesRes.messages, ...filteredCurrentMessages],
            "Id",
          );

          filteredNewMessages.unshift({
            Id: filteredNewMessages[0].Id - 0.9,
            FileType: "buffer",
            messageFromId: filteredNewMessages[0].Id,
            direction: "top",
            action: "defaultScroll",
          });

          if (currentCallId === lastCallIdRef.current) {
            if (messagesFeedRef.current?.scrollTop === 0)
              messagesFeedRef.current.scrollTop = 1;
            handleMessagesList(filteredNewMessages);
          }

          saveUserMessagesToState([...filteredNewMessages], phoneNumber);
        }
      } catch (error) {
        console.log("loadMoreMessagesOnLoad err: ", error);
      } finally {
        isLoadMoreMessages = true;
      }
    }

    async function onUserChangedActions(phoneNumber, goToMessageId = null) {
      isMoreMessages = false;
      const currentCallId = ++lastCallIdRef.current;
      setChatLoading(true);
      setMessagesLoading(true);
      handleMessagesList([]);
      setNoMoreMessages(false);

      closeConversationCasesPopup();

      try {
        const findChatUserInList = chatUsersFunnerRef.current.notMyChats.find(
          (user) => user.UserDisplayPhoneNumber === phoneNumber,
        );
        if (currentCallId === lastCallIdRef.current) {
          switchToChatType(
            findChatUserInList?.Platform === "fb" ? "facebook" : "whatsapp",
          );
        }

        searchMessagesArr.current = null;
        clearSearch();

        let thisMessages,
          userExistsInObject = false;
        if (
          chatUsersMessagesRef.current.some((user) => user.id === phoneNumber)
        ) {
          userExistsInObject = true;
          const existingMessages = chatUsersMessagesRef.current.find(
            (user) => user.id === phoneNumber,
          ).messages;
          // const missingMessages = await getChatMissingHistory(phoneNumber);
          const resultMessages = sortListByParam(
            [
              ...existingMessages,
              // ...missingMessages.filter(
              //   (missingMsg) =>
              //     !existingMessages.some((msg) => msg.Id === missingMsg.Id),
              // ),
            ],
            "Id",
          );

          if (currentCallId === lastCallIdRef.current) {
            handleMessagesList(resultMessages);
            setIsActive(findChatUserInList.isRestricted24);
          }

          thisMessages = resultMessages;
          saveUserMessagesToState([...resultMessages], phoneNumber);
          //* Handling video messages
          videosLoading.current = resultMessages
            .filter((msg) => msg.FileType === "video")
            .map((msg) => msg.Id);
        } else {
          const history = await getChatMessages(1, phoneNumber);

          if (history.result) {
            const reverseMessages = sortListByParam(history?.messages, "Id");
            //! if (reverseMessages.length >= LOAD_MESSAGES_COUNT) {
            thisMessages = [
              {
                Id: reverseMessages[0].Id - 0.9,
                FileType: "buffer",
                messageFromId: reverseMessages[0].Id,
                direction: "top",
                action: "defaultScroll",
              },
              ...reverseMessages,
            ];

            if (currentCallId === lastCallIdRef.current) {
              handleMessagesList(thisMessages);
              setIsActive(
                history?.isActive !== null
                  ? history.isActive
                  : selectedUserRef.current?.isRestricted24,
              );
            }
            saveUserMessagesToState([...thisMessages], phoneNumber);
            //!
            if (reverseMessages.length >= LOAD_MESSAGES_COUNT)
              loadMoreMessagesOnLoad(phoneNumber, thisMessages, currentCallId);

            //* Handling video messages
            videosLoading.current = thisMessages
              .filter((msg) => msg.FileType === "video")
              .map((msg) => msg.Id);
            //! } else {
            //   if (currentCallId === lastCallIdRef.current) {
            //     handleMessagesList(reverseMessages);
            //     setIsActive(
            //       history?.isActive !== null
            //         ? history.isActive
            //         : selectedUserRef.current?.isRestricted24,
            //     );
            //   }
            //   saveUserMessagesToState([...reverseMessages], phoneNumber);
            //   thisMessages = reverseMessages;
            //! }
          } else {
            setMessages([
              {
                FileType: "error",
                text: localizations["he"].chat.notifications.historyError,
              },
            ]);
          }
        }

        if (selectedChatUser?.UserDisplayPhoneNumber) {
          getChatUserConversations();
        }
        getChatUserConfig(phoneNumber);

        if (currentCallId === lastCallIdRef.current) {
          if (videosLoading.current?.length) {
            let i = 0;
            const waitForVideosLoadedInterval = setInterval(() => {
              if (!videosLoading.current.length || i >= 60) {
                clearInterval(waitForVideosLoadedInterval);
                if (goToMessageId) {
                  scrollToMessage(goToMessageId);
                } else {
                  scrollToLastMessage(true);
                }

                setMessagesLoading(false);

                loadMissingMessages(phoneNumber, userExistsInObject);

                setTimeout(() => {
                  isMoreMessages = true;
                }, 500);
              }
              i++;
            }, 100);
          } else {
            setTimeout(() => {
              if (goToMessageId) {
                scrollToMessage(goToMessageId);
              } else {
                scrollToLastMessage(true);
              }

              setMessagesLoading(false);

              loadMissingMessages(phoneNumber, userExistsInObject);

              setTimeout(() => {
                isMoreMessages = true;
              }, 500);
            }, 100);
          }
        }

        async function loadMissingMessages(phoneNumber, userExistsInObject) {
          if (userExistsInObject) {
            const existingMessages = chatUsersMessagesRef.current.find(
              (user) => user.id === phoneNumber,
            ).messages;
            const missingMessages = await getChatMissingHistory(phoneNumber);
            const resultMessages = sortListByParam(
              [
                ...existingMessages,
                ...missingMessages.filter(
                  (missingMsg) =>
                    !existingMessages.some((msg) => msg.Id === missingMsg.Id),
                ),
              ],
              "Id",
            );
            if (
              phoneNumber === selectedUserRef.current.UserDisplayPhoneNumber
            ) {
              handleMessagesList(resultMessages);
            }

            saveUserMessagesToState([...resultMessages], phoneNumber);
          }
        }
      } catch (error) {
        console.log("onUserChangedActions error: ", error);
        setMessagesLoading(false);
      } finally {
        setChatLoading(false);
        textareaRef.current.focus();
      }
    }
    const onVideoMessageLoaded = (id) => {
      videosLoading.current = videosLoading.current.filter(
        (msgId) => msgId !== id,
      );
    };
    async function handleFavouriteMessageClick(thisPhoneNumber, thisMessageId) {
      if (selectedUserRef.current.UserDisplayPhoneNumber === thisPhoneNumber) {
        scrollToMessage(thisMessageId);
        handleGoToFavouriteMessageState(null);
      } else {
        const findChatUserInList = chatUsersFunnerRef.current.notMyChats.find(
          (user) => user.UserDisplayPhoneNumber === thisPhoneNumber,
        );
        // onUserCardClick({}, findChatUserInList);
        openChatByContact(findChatUserInList);
        //! Timeout to prevent user action when phone number value changes
        setTimeout(() => {
          handleGoToFavouriteMessageState(null);
        }, 200);
        onUserChangedActions(thisPhoneNumber, thisMessageId);
      }
    }

    async function handleBotStatus(state) {
      await handleChatUserConfig(
        selectedChatUser.UserDisplayPhoneNumber,
        { isBotActive: state },
        true,
      );
    }

    async function handleUpload(files) {
      resetSendUpload();
      if (chatType === "funner" && resetSendUploadPriority)
        resetSendUploadPriority();

      const thisLoadedFile = files[0];
      const maxSizeInBytes = 20 * 1024 * 1024; // 20 MB
      if (thisLoadedFile.size > maxSizeInBytes) {
        //console.log('File size exceeds the limit of 20 MB.');
        handleSetNotif(
          localizations["he"].chat.notifications.uploadFile.exceedsSizeLimit,
          "error",
        );

        return;
      }

      const fileType = getFileType(thisLoadedFile.name);

      let fileTypeImg = null;
      if (fileType === "pdf") {
        fileTypeImg = uploadPreview;
      } else if (fileType === "docx") {
        fileTypeImg = docx;
      } else if (fileType === "xlsx") {
        fileTypeImg = xlsx;
      } else if (fileType === "zip") {
        fileTypeImg = zip;
      } else if (fileType === "image" || fileType.match(/(png|jpe?g|gif)$/i)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          fileTypeImg = e.target.result;
          handleSendUploadState({ fileTypeImg: fileTypeImg });
        };

        compressImage(thisLoadedFile, (compressedLoadedFile) => {
          handleSendUploadState({
            file: new File([compressedLoadedFile], compressedLoadedFile.name, {
              type: "image/jpeg",
            }),
          });
          requestAnimationFrame(() =>
            reader.readAsDataURL(compressedLoadedFile),
          );
        });
      } else if (fileType.match(/(mp4|webm|avi|mov|mkv|flv|wmv)$/i)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          fileTypeImg = e.target.result;
          handleSendUploadState({ fileTypeImg: fileTypeImg, isVideo: true });
        };

        // const compressedLoadedFile = await compressVideo();
        reader.readAsDataURL(thisLoadedFile);
      }

      handleSendUploadState({
        isUpload: true,
        file: thisLoadedFile,
        fileTypeImg: fileTypeImg,
      });
    }
    function handleDirectFileUpload(file) {
      resetSendUpload();
      if (chatType === "funner" && resetSendUploadPriority)
        resetSendUploadPriority();

      const fileType = getFileType(file.Link);
      let fileTypeImg = null;
      if (fileType === "pdf") {
        fileTypeImg = uploadPreview;
      } else if (fileType === "docx") {
        fileTypeImg = docx;
      } else if (fileType === "xlsx") {
        fileTypeImg = xlsx;
      } else if (fileType === "zip") {
        fileTypeImg = zip;
      } else if (fileType === "image" || fileType.match(/(png|jpe?g|gif)$/i)) {
        fileTypeImg = file.Link;
      } else if (fileType.match(/(mp4|webm|avi|mov|mkv|flv|wmv)$/i)) {
        fileTypeImg = file.Link;
      }

      handleSendUploadState({
        isUpload: true,
        file: {
          name: file.Name,
          link: file.Link,
        },
        fileTypeImg: fileTypeImg,
      });
    }
    async function handleForm(textareaValue) {
      setIsMessageSending(true);
      try {
        const inputValue = textareaValue;
        const currentDate = getDateParts();
        const uniqTempId = generateUniqueId([...messagesListRef.current], 6);

        let newMessageObj = {
          DateCreate: `${currentDate.day}/${currentDate.month} ${currentDate.hours}:${currentDate.minutes}`,
          DateCreatedOriginal: `${currentDate.year}-${currentDate.month}-${currentDate.day}`,
          Message: inputValue,
          Type: "outcoming",
          Id: null,
          TempId: uniqTempId,
          File: null,
          FileType:
            sendToPlatformsStatusRef.current === "note" ? "note" : "text",
          GlobalUserId: userDataRef.current.Udid,
          GlobalUserName: "",
          GlobalUserImage: "",
          Reply: null,
          StatusHeb: "",
          Object: null,
          Status: "sending",
          reactions: null,
          IsEncrypted: false,
          IsForwarded: null,
          Platform: null,
          WebsitePreviewObj: null,
        };

        taggedUsersNotificationIds = funnerUsers
          .filter((user) =>
            textareaValue.includes(
              `@${user.FirstName || ""} ${user.LastName || ""}`,
            ),
          )
          .map((user) => user.Udid);

        // funnerUsers.map((user) => {
        //   if (
        //     textareaValue.includes(
        //       `@${user.FirstName || ""} ${user.LastName || ""}`,
        //     )
        //   ) {
        //     taggedUsersNotificationIds.push(user.Udid);
        //   }
        //   return true;
        // });

        const formData = new FormData();
        let params = {
          userid: userDataRef.current.Udid,
          message: inputValue,
          id: selectedUserRef.current.UserDisplayPhoneNumber,
          isWhatsapp: sendToPlatformsStatusRef.current === "whatsapp",
          isFacebook: sendToPlatformsStatusRef.current === "facebook",
          isInstagram: sendToPlatformsStatusRef.current === "instagram",
          taggedUsersNotificationIds:
            sendToPlatformsStatusRef.current === "note"
              ? taggedUsersNotificationIds
              : null,
          messageType:
            sendToPlatformsStatusRef.current === "note" ? "note" : null,
          tempId: uniqTempId,
        };
        if (sendUpload?.isUpload && !sendUpload.file?.link)
          params.companyJSON = JSON.stringify(businessInfo);
        else params.company = businessInfo;

        if (sendUpload?.isUpload) {
          if (params.messageType !== "note") params.messageType = "media";
          if (sendUpload.file?.link) {
            const fileLink = sendUpload.file.link;
            newMessageObj = {
              ...newMessageObj,
              File: fileLink,
              FileType:
                params.messageType !== "note"
                  ? GetFileTypeByLink(fileLink)
                  : "note",
              Status: "uploading",
            };
            params.imageLink = fileLink;
          } else {
            Object.keys(params).forEach((objKey) =>
              formData.append(objKey, params[objKey]),
            );
            formData.append("uploadFile", sendUpload.file);
            const fileType = getFileType(sendUpload.file.name);

            if (fileType.match(/(mp4|webm|avi|mov|mkv|flv|wmv)$/i)) {
              const video = document.createElement("video");

              const objectURL = URL.createObjectURL(sendUpload.file);
              video.src = objectURL;

              video.addEventListener("loadedmetadata", function () {
                newMessageObj = {
                  ...newMessageObj,
                  File: objectURL,
                  FileType: params.messageType !== "note" ? "video" : "note",
                  FileDimensions: {
                    width: video.videoWidth,
                    height: video.videoHeight,
                  },
                  Status: "uploading",
                };

                // sendActions(true);
                // UseFetchPostForm(
                //   "/api/whatsapp/SendMessageFile",
                //   formData,
                //   "messages",
                // );

                URL.revokeObjectURL(objectURL);
              });
            } else if (
              fileType === "image" ||
              fileType.match(/(png|jpe?g|gif|webp)$/i)
            ) {
              newMessageObj = {
                ...newMessageObj,
                File: sendUpload.fileTypeImg,
                FileType: params.messageType !== "note" ? "image" : "note",
                Status: "uploading",
              };
            } else {
              console.log("else var");
              console.log(params);
              newMessageObj = {
                ...newMessageObj,
                FileType: params.messageType !== "note" ? fileType : "note",
                File: sendUpload.file.name,
              };
            }
            // params = formData;
          }
        } else if (sendUploadPriority?.isUpload) {
          params.imageLink = sendUploadPriority.file.link;
          params.messageType = params.messageType !== "note" ? "media" : "note";
        } else if (sendReply.isReply) {
          if (params.messageType !== "note") params.messageType = "reply";
          params.replyMessageId = +sendReply.message.Id;
          newMessageObj.Reply = sendReply.message;
        }

        console.log(newMessageObj);

        sendActions();

        const checkRes = async () => {
          const sendMsgRes =
            sendUpload?.isUpload && !sendUpload.file?.link
              ? await UseFetchPostForm(
                  "/api/services/sendMessageRefactoringFile",
                  formData,
                  "messages",
                )
              : await UseFetchPost(
                  "/api/services/sendMessageRefactoring",
                  params,
                  "messages",
                );

          if (sendMsgRes?.data?.result) {
            if (params.messageType === "note") {
              const newMesssagesList = [...messagesListRef.current];
              const thisMessage = newMesssagesList.find(
                (msg) => msg.TempId === uniqTempId,
              );
              console.log(thisMessage);
              thisMessage.File = sendMsgRes.data.file;
              thisMessage.Status = "pending system";
              handleMessagesList(newMesssagesList);
            }
          } else {
            handleSetNotif(
              localizations["he"].chat.notifications.sendMessage.sendTextError,
              "error",
            );
          }
        };
        checkRes();

        function sendActions() {
          const newMesssagesList = [...messagesListRef.current];
          newMesssagesList.push(newMessageObj);
          handleMessagesList(newMesssagesList);
          // setTextareaValue("");
          taggedUsersNotificationIds = [];
          if (sendUpload?.isUpload) resetSendUpload();
          if (sendUploadPriority?.isUpload) resetSendUploadPriority();
          if (sendReply.isReply) resetSendReply();

          setTimeout(() => scrollToLastMessage(), 200);
        }
      } catch (error) {
        console.log("Sending message error: ", error);
        handleSetNotif(
          localizations["he"].chat.inputArea.noServiceError,
          "error",
        );
      } finally {
        setIsMessageSending(false);
      }
    }
    async function handleEmojiReply(e) {
      try {
        resetSendEmojiReply();

        const value = e.target.textContent.trim().replace(/\s/g, "");
        const whatsappPhoneNumber = selectedChatUser.UserDisplayPhoneNumber;
        const globalID = userDataRef.Udid;
        const id = sendEmojiReply.message.Id;

        const sendMsgRes = await UseFetchPost(
          "/api/services/sendMessageRefactoring",
          {
            userid: globalID,
            message: value,
            id: whatsappPhoneNumber,
            messageType: "reaction",
            replyMessageId: +id,
            isWhatsapp: true,
            company: businessInfo,
          },
          "messages",
        );

        console.log(sendMsgRes);
        if (sendMsgRes?.data?.result) {
          // resetSendEmojiReply();
        }
      } catch (error) {
        console.log("Send Emoji Reply error: ", error);
      }
    }

    function initSocketListener() {
      connection.on("ReceiveMessage", async function (message) {
        const messageObject = JSON.parse(message);
        onSocketReceiveMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketReceiveMessage", sendContent);
        }
      });
      connection.on("MessageStatusUpdate", async function (message) {
        const messageObject = JSON.parse(message);
        onSocketUpdateMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketMessageStatusUpdate", sendContent);
        }
      });
      connection.on("ReceiveTemplateMessage", async function (message) {
        const messageObject = JSON.parse(message);
        onSocketReceiveTemplateMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketReceiveTemplateMessage", sendContent);
        }
      });
      connection.on("botStatusUpdate", async function (message) {
        const messageObject = JSON.parse(message);
        onSocketBotStatusUpdate(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("botStatusUpdate", sendContent);
        }
      });
      connection.onreconnected((connectionId) => {
        if (chatType === "funner") clearChatUsersMessages();
        setTimeout(() => {
          onUserChangedActions(selectedUserId);
        }, 0);
      });
    }
    function onSocketReceiveMessage(messageObject) {
      console.log("Receive Message: ", messageObject);
      try {
        const newMessage = messageObject.messages[0];

        if (
          selectedUserRef.current &&
          selectedUserRef.current.UserDisplayPhoneNumber === messageObject.phone
        ) {
          const existingMessageIndex = messagesListRef.current.findIndex(
            (msg) =>
              msg.Id === newMessage.Id ||
              (newMessage.TempId && msg.TempId === newMessage.TempId),
            // (msg) => msg.Id === newMessage.Id || msg.TempId === 12345678,
          );

          let newMessagesList = [...messagesListRef.current];
          if (existingMessageIndex !== -1) {
            newMessagesList[existingMessageIndex] = {
              ...newMessagesList[existingMessageIndex],
              ...newMessage,
              Status:
                (newMessage.Status === "pending system" &&
                  (newMessagesList[existingMessageIndex].Status === "sent" ||
                    newMessagesList[existingMessageIndex].Status ===
                      "delivered" ||
                    newMessagesList[existingMessageIndex].Status === "read")) ||
                (newMessage.Status === "sent" &&
                  (newMessagesList[existingMessageIndex].Status ===
                    "delivered" ||
                    newMessagesList[existingMessageIndex].Status === "read")) ||
                (newMessage.Status === "delivered" &&
                  newMessagesList[existingMessageIndex].Status === "read")
                  ? newMessagesList[existingMessageIndex].Status
                  : newMessage.Status,
            };
          } else {
            newMessagesList.push(newMessage);
            if (searchMessagesActive) {
              searchMessagesArr.current.push(newMessage);
              setTimeout(() => {
                handleSearchQuery(searchQueryRef.current);
              }, 100);
            }
            if (
              newMessage.Type === "outcoming" ||
              (newMessage.Type === "incoming" && !isGoBotBtnRef.current)
            ) {
              setTimeout(() => {
                scrollToLastMessage();
              }, 100);
            }
          }

          if (!newMessagesList.some((msg) => !msg.Id))
            newMessagesList = sortListByParam(newMessagesList, "Id");
          handleMessagesList(newMessagesList);
          setIsActive(messageObject.isActive);
          if (
            chatUsersMessagesRef.current.some(
              (user) =>
                user.id === selectedUserRef.current.UserDisplayPhoneNumber,
            )
          )
            saveUserMessagesToState(
              [...newMessagesList],
              selectedUserRef.current.UserDisplayPhoneNumber,
            );
        }
      } catch (error) {
        console.log("onSocketReceiveMessage error", error);
      }
    }
    function onSocketReceiveTemplateMessage(messageObject) {
      console.log("Receive Template Message: ", messageObject);
      try {
        if (
          selectedUserRef.current &&
          selectedUserRef.current.UserDisplayPhoneNumber === messageObject.phone
        ) {
          const existingMessageIndex = messagesListRef.current.findIndex(
            (msg) =>
              msg.TempId === messageObject.tempId ||
              msg.tempId === messageObject.tempId,
          );

          if (existingMessageIndex !== -1) {
            let newMessagesList = [...messagesListRef.current];
            const currentMessage = newMessagesList[existingMessageIndex];
            newMessagesList[existingMessageIndex] = {
              ...currentMessage,
              MessageId: messageObject.messageId,
              Status: messageObject.Status,
              StatusHeb: messageObject.StatusHeb,
            };

            handleMessagesList(newMessagesList);

            if (
              chatUsersMessagesRef.current.some(
                (user) =>
                  user.id === selectedUserRef.current.UserDisplayPhoneNumber,
              )
            )
              saveUserMessagesToState(
                [...newMessagesList],
                selectedUserRef.current.UserDisplayPhoneNumber,
              );
          }
        }
      } catch (error) {
        console.log("onSocketReceiveMessage error", error);
      }
    }
    function onSocketUpdateMessage(messageObject) {
      console.log("onSocketUpdateMessage: ", messageObject);
      const value = messageObject.entry[0].changes[0].value;
      const statuses = value.statuses[0];

      if (
        selectedUserRef.current &&
        selectedUserRef.current.UserDisplayPhoneNumber === statuses.recipient_id
      ) {
        const existingMessageIndex = messagesListRef.current.findIndex(
          (msg) =>
            msg.messageId === statuses.id ||
            msg.MessageId === statuses.id ||
            (statuses.tempId && msg.TempId === statuses.tempId),
          // (msg) => msg.Id === newMessage.Id || msg.TempId === 12345678,
        );

        // console.log(existingMessageIndex);

        if (existingMessageIndex === -1) return;
        let newMessagesList = [...messagesListRef.current];
        newMessagesList[existingMessageIndex] = {
          ...newMessagesList[existingMessageIndex],
          Status:
            (statuses.status === "sent" &&
              (newMessagesList[existingMessageIndex].Status === "delivered" ||
                newMessagesList[existingMessageIndex].Status === "read")) ||
            (statuses.status === "delivered" &&
              newMessagesList[existingMessageIndex].Status === "read")
              ? newMessagesList[existingMessageIndex].Status
              : statuses.status,
        };

        handleMessagesList(newMessagesList);

        if (
          chatUsersMessagesRef.current.some(
            (user) =>
              user.id === selectedUserRef.current.UserDisplayPhoneNumber,
          )
        )
          saveUserMessagesToState(
            [...newMessagesList],
            selectedUserRef.current.UserDisplayPhoneNumber,
          );
      }
    }
    // useEffect(() => {
    //   console.log("messages ", messages);
    // }, [messages]);
    function onSocketBotStatusUpdate(messageObject) {
      try {
        const configObject = JSON.parse(messageObject.configObj);
        handleChatUserConfig(
          messageObject.phoneId,
          { isBotActive: configObject.isBotActive },
          false,
          false,
        );
      } catch (error) {
        console.log("onSocketBotStatusUpdate error: ", error);
      }
    }

    function activeTabChangesListener() {
      // eslint-disable-next-line no-undef
      chrome.runtime.onMessage.addListener(async function (
        message,
        sender,
        sendResponse,
      ) {
        if (message.type === "socketReceiveMessage") {
          const { messageObject } = message.content;
          onSocketReceiveMessage(messageObject);
        }
        if (message.type === "socketMessageStatusUpdate") {
          const { messageObject } = message.content;
          onSocketUpdateMessage(messageObject);
        }
        if (message.type === "socketReceiveTemplateMessage") {
          const { messageObject } = message.content;
          onSocketReceiveTemplateMessage(messageObject);
        }
        if (message.type === "botStatusUpdate") {
          const { messageObject } = message.content;
          onSocketBotStatusUpdate(messageObject);
        }
      });
    }

    function handleOutsideClickElements(e) {
      const targetClicked = e.target;

      if (
        messagesContextRef.current &&
        !messagesContextRef.current.contains(targetClicked)
      ) {
        closeMessagesContext();
      }
      if (
        emojiReplyRef.current &&
        !emojiReplyRef.current.contains(targetClicked) &&
        !targetClicked.closest(".smile-to-react")
      ) {
        resetSendEmojiReply();
      }
    }

    //! Conversation Cases
    const handleConversationCasesAnchor = (e) => {
      setChatUserConversationsAnchor(e.currentTarget);
      getChatUserConversations();
    };
    const closeConversationCasesPopup = () => {
      setChatUserConversationsAnchor(null);
    };
    async function getChatUserConversations() {
      try {
        setChatUserConversationsLoading(true);
        const res = await UseFetchGet(
          `/api/services/getChatUserConversations/${selectedChatUser?.UserDisplayPhoneNumber}/1/10`,
          {},
          null,
          undefined,
          "history",
        );

        if (res?.data?.result) {
          setChatUserConversations(res.data.readyLogsList);
        }
      } catch (error) {
        console.error("getChatUserConversations error: ", error);
      } finally {
        setChatUserConversationsLoading(false);
      }
    }

    useEffect(() => {
      if (tabActiveRef.current === false && applicationType === "extension") {
        activeTabChangesListener();
      }

      document.addEventListener("click", handleOutsideClickElements);

      return () => {
        document.removeEventListener("click", handleOutsideClickElements);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (selectedUserId && !goToFavouriteMessageStateRef.current) {
        onUserChangedActions(selectedUserId);
      }
    }, [selectedUserId]);
    useEffect(() => {
      if (goToFavouriteMessageState) {
        handleFavouriteMessageClick(
          goToFavouriteMessageState.phoneNumber,
          goToFavouriteMessageState.messageId,
        );
      }
    }, [goToFavouriteMessageState]);

    useEffect(() => {
      selectedUserRef.current = { ...selectedChatUser };
    }, [selectedChatUser]);

    useEffect(() => {
      if (messages.length) {
        //! Observe buffer messages
        const scrollObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                loadMoreMessagesOnTrigger(entry);
              }
            });
          },
          {
            root: messagesFeedRef.current,
            threshold: 0,
            rootMargin: `3000px 0px 3000px 0px`,
          },
        );
        const helperScrollObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                loadMoreMessagesOnTrigger(entry);
              }
            });
          },
          {
            root: messagesFeedRef.current,
            threshold: 0,
            rootMargin: `0px`,
          },
        );

        messages.forEach((message) => {
          if (message.FileType === "buffer") {
            const bufferElement = document.getElementById(
              chatType === "fireberry"
                ? `fireberry-buffer-${message.Id}`
                : `funner-buffer-${message.Id}`,
            );
            if (bufferElement) {
              scrollObserver.observe(bufferElement);
              helperScrollObserver.observe(bufferElement);
              // if (message.action === "defaultScroll")
              //   defaultScrollObserver.observe(bufferElement);
              // else if (message.action === "search")
              //   searchObserver.observe(bufferElement);
            }
          }
        });

        //! Date indicators sticky positioning
        // const observer = new IntersectionObserver(
        //   (entries) => {
        //     entries.forEach((entry) => {
        //       const { isIntersecting, target } = entry;

        //       if (isIntersecting) {
        //         console.log(target);
        //       } else {
        //       }
        //     });
        //   },
        //   {
        //     root: messagesFeedRef.current,
        //     threshold: [0]
        //   },
        // );

        // dateRefs.current.forEach((ref) => {
        //   if (ref) observer.observe(ref);
        // });

        function checkIntersection() {
          const containerRect = messagesFeedRef.current.getBoundingClientRect();

          dateRefs.current.forEach((target) => {
            const targetRect = target.getBoundingClientRect();

            // Проверка, пересекает ли верхний край элемента верх контейнера
            if (
              targetRect.top <= containerRect.top &&
              targetRect.bottom > containerRect.top
            ) {
              console.log(
                `Элемент ${target.textContent} пересёк верхнюю границу контейнера`,
              );
              target.classList.add("intersecting");
            } else {
              target.classList.remove("intersecting");
            }
          });
        }

        if (messagesFeedRef.current)
          messagesFeedRef.current.addEventListener("scroll", checkIntersection);

        return () => {
          scrollObserver.disconnect();
          // dateRefs.current.forEach((ref) => {
          //   if (ref) observer.unobserve(ref);
          // });
          if (messagesFeedRef.current)
            messagesFeedRef.current.removeEventListener(
              "scroll",
              checkIntersection,
            );
        };
      }
    }, [messages]);

    useEffect(() => {
      if (isConnection) {
        initSocketListener();
      }
    }, [isConnection]);

    useEffect(() => {
      if (sendUploadPriority?.isUpload) {
        resetSendUpload();
      }
    }, [sendUploadPriority]);

    useEffect(() => {
      const platformsObject = IsJsonString(businessInfo.CompanyPlatforms)
        ? JSON.parse(businessInfo.CompanyPlatforms)
        : businessInfo.CompanyPlatforms;

      if (platformsObject?.chatCustomizationSettings) {
        setChatCustomizationSettings([
          ...platformsObject.chatCustomizationSettings,
        ]);
      }
    }, [businessInfo?.CompanyPlatforms]);

    return (
      <div
        className={`root-whatsapp rtl chat ${
          isViewsPage ? "inside-body" : ""
        } ${chatType === "funner" ? "funner-global-messanger-chat" : ""}  ${
          selectedChatUser?.Platform === "fb" ? "fb-chat" : ""
        }`}
        style={{ overflow: "hidden", ...containerStyle }}
      >
        <div
          className={`page whatsappPage ${
            "_" + (userConfig?.fontSize || "14")
          } width main-content ${
            chatType === "funner" ? "funner-global-messanger-chat" : ""
          } ${
            userConfig.isMssgRTL === false || userConfig.isMssgRTL === "false"
              ? "swap-msgs"
              : ""
          }`}
          style={{
            //?
            position: "static",
            // position: applicationType === "extension" ? "static" : "fixed",
            width: "100%",
          }}
        >
          <div className="chat-sidebar-container" data-sidebar-container="chat">
            <div
              className={`chat-sidebar-wrap chat-accordion chat-accordion--templates ${
                !isTemplatesAccordionOpen ? "hidden" : ""
              }`}
              data-sidebar="chat"
            >
              <TemplatesMenu
                templatesLoading={templatesLoading}
                templatesList={templatesList.templates}
                whatsappTemplatesList={templatesList.whatsappTemplates}
                languages={templatesList.languages}
                closeAccordion={closeAccordion}
                chatUser={selectedChatUser}
                chatUserName={selectedChatUser?.FullName}
                currentContact={selectedChatUser?.UserDisplayPhoneNumber}
                globalID={selectedChatUser?.userglobalId}
                handleSetNotif={handleSetNotif}
                fetchWhatsappTemplates={fetchWhatsappTemplates}
                templatesWithSend={true}
                setIsTemplateSending={setIsTemplateSending}
                resetTemplateState={resetTemplateState}
                messagesListRef={messagesListRef}
                userDataRef={userDataRef}
                handleMessagesList={handleMessagesList}
                scrollToLastMessage={scrollToLastMessage}
              />
            </div>
            <div
              className="chat-content-wrap"
              data-sidebar-content="chat"
              style={{ minWidth: 0 }}
            >
              <ChatHeader
                chatType={chatType}
                selectedChatUser={selectedChatUser}
                selectedUserId={selectedUserId}
                chatLoading={chatLoading}
                selectedChatUserConfig={selectedChatUserConfig}
                handleChatUserConfig={handleChatUserConfig}
                handleBotStatus={handleBotStatus}
                funnerUsers={funnerUsers}
                closeChat={closeChat}
                chatUsersLabelsList={chatUsersLabelsList}
                toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
                userData={userData}
                userConfig={userConfig}
                updateChatUser={updateChatUser}
                isTemplatesAccordionOpen={isTemplatesAccordionOpen}
                isNavOpen={isNavOpen}
                handleSearchInput={handleSearchInput}
                searchQuery={searchQuery}
                searchMessagesActive={searchMessagesActive}
                setSearchMessagesActive={setSearchMessagesActive}
                filteredSearchMessages={filteredSearchMessages}
                clearSearch={clearSearch}
                onSearchMessageClick={onSearchMessageClick}
                chatFolded={chatFolded}
                isHashtag={isHashtag}
                disableChatGoBack={disableChatGoBack}
                handleConversationCasesAnchor={handleConversationCasesAnchor}
                chatUserConversations={chatUserConversations}
              />
              {chatLoading && (
                <LinearProgress color="secondary" sx={{ flex: "0 0 4px" }} />
              )}

              <ChatUserConversations
                anchorEl={chatUserConversationsAnchor}
                chatUserConversations={chatUserConversations}
                setChatUserConversations={setChatUserConversations}
                funnerUsers={funnerUsers}
                onClose={closeConversationCasesPopup}
                chatCustomizationSettings={chatCustomizationSettings}
                userConfig={userConfig}
                onMessagesContext={onMessagesContext}
                onEmojiReplyIconClicked={onEmojiReplyIconClicked}
                scrollToMessage={scrollToMessage}
                templatesList={templatesList}
                chatUsersGlobalRef={chatUsersGlobalRef}
                forwardMessage={forwardMessage}
                toggleMessageInForwardList={toggleMessageInForwardList}
                handleMediaPreview={handleMediaPreview}
                businessInfo={businessInfo}
                chatUserConversationsLoading={chatUserConversationsLoading}
              />

              <div
                // ref={messagesFeedRef}
                // onScroll={(e) => {
                //   scrollDirection =
                //     e.target.scrollTop > prevScrollTop ? "bottom" : "top";
                //   prevScrollTop = e.target.scrollTop;
                // }}
                // className={`chat-content perfect-scrollbar context-menu-container ${ chatLoading ? "no-scroll" : "" }`}
                className={`chat-content perfect-scrollbar context-menu-container`}
              >
                <Box
                  id="funner-global__messages-container"
                  className="messages-container"
                  ref={messagesFeedRef}
                  onScroll={(e) => {
                    const scroller = e.target;
                    scrollDirection =
                      scroller.scrollTop > prevScrollTop ? "bottom" : "top";
                    prevScrollTop = scroller.scrollTop;

                    if (
                      scroller.scrollHeight -
                        scroller.offsetHeight -
                        scroller.scrollTop >
                        100 &&
                      !isGoBotBtn
                    ) {
                      setIsGoBotBtn(true);
                      isGoBotBtnRef.current = true;
                    } else if (
                      scroller.scrollHeight -
                        scroller.offsetHeight -
                        scroller.scrollTop <=
                        100 &&
                      isGoBotBtn
                    ) {
                      isGoBotBtnRef.current = false;
                      setIsGoBotBtn(false);
                    }
                  }}
                  sx={{
                    background:
                      (chatCustomizationSettings?.find(
                        (option) => option.type === "conversationBackground",
                      )?.value === "image"
                        ? `url(${
                            chatCustomizationSettings.find(
                              (option) =>
                                option.type === "conversationBackground",
                            ).customColor.backgroundImage
                          }) center / cover no-repeat`
                        : chatCustomizationSettings?.find(
                            (option) =>
                              option.type === "conversationBackground",
                          )?.value === "color"
                        ? chatCustomizationSettings.find(
                            (option) =>
                              option.type === "conversationBackground",
                          ).customColor.background
                        : "#fff") + "!important",
                    "&::-webkit-scrollbar": {
                      bgcolor: "rgba(255, 255, 255, 0.25) !important",
                    },
                  }}
                >
                  {noMoreMessages && !messagesLoading && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="info">
                        {localizations["he"].chat.notifications.startOfConv}
                      </Alert>
                    </Box>
                  )}
                  {messages?.map((message, index) => {
                    if (message.FileType === "error")
                      return <Alert severity="error">{message.text}</Alert>;
                    if (message.FileType === "reaction") return null;
                    if (message.FileType === "buffer")
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            height: "2px",
                            visibility: "hidden",
                          }}
                          id={
                            chatType === "fireberry"
                              ? `fireberry-buffer-${message.Id}`
                              : `funner-buffer-${message.Id}`
                          }
                          key={`buffer-${message.Id}`}
                        ></Box>
                      );

                    const prevMessage = messages[index - 1];

                    const prevMessageDate = prevMessage?.DateCreatedOriginal;
                    const currentMessageDate = message.DateCreatedOriginal;

                    const showDateHeader =
                      prevMessageDate !== currentMessageDate;

                    const isSameSender =
                      message.BotName !== null && message.BotName !== undefined
                        ? message.BotName === prevMessage.BotName
                        : prevMessage?.Type === message.Type &&
                          prevMessage?.GlobalUserId === message.GlobalUserId;
                    const isFunnerAvatarVisible =
                      !isSameSender ||
                      prevMessage?.FileType === "info" ||
                      showDateHeader;

                    return (
                      <Fragment key={message.TempId || message.Id}>
                        {showDateHeader && (
                          <ChatDateIndicator
                            currentMessageDate={currentMessageDate}
                            container={messagesFeedRef.current}
                          />
                        )}

                        {/* {message.Reply ? ( */}
                        {message.FileType === "reply" ? (
                          <SingleMessageReplyTemplate
                            key={message.TempId || message.Id}
                            message={message}
                            userConfig={userConfig}
                            onMessagesContext={onMessagesContext}
                            onEmojiReplyIconClicked={onEmojiReplyIconClicked}
                            scrollToReplyMessage={scrollToMessage}
                            templatesList={templatesList}
                            funnerUsers={funnerUsers}
                            isFunnerAvatarVisible={isFunnerAvatarVisible}
                            chatUsersGlobalRef={chatUsersGlobalRef}
                            chatCustomizationSettings={
                              chatCustomizationSettings
                            }
                            selectable={
                              forwardMessage.visible ||
                              forwardMessagesWebhookSelectable
                            }
                            selected={
                              forwardMessage.visible
                                ? forwardMessage.messagesList.includes(
                                    message.Id,
                                  )
                                : forwardMessagesWebhookSelectable
                                ? forwardMessagesWebhookList.some(
                                    (msg) => msg.Id === message.Id,
                                  )
                                : false
                            }
                            toggleSelected={
                              forwardMessage.visible
                                ? toggleMessageInForwardList
                                : forwardMessagesWebhookSelectable
                                ? toggleMessageInWebhookList
                                : null
                            }
                            isShowDate={showDateHeader}
                          />
                        ) : message.FileType === "info" ? (
                          <InfoMessageTemplate
                            key={message.TempId || message.Id}
                            message={message}
                            userConfig={userConfig}
                            chatCustomizationSettings={
                              chatCustomizationSettings
                            }
                          />
                        ) : (
                          <SingleMessageTemplate
                            key={message.TempId || message.Id}
                            message={message}
                            userConfig={userConfig}
                            onMessagesContext={onMessagesContext}
                            onEmojiReplyIconClicked={onEmojiReplyIconClicked}
                            handleMediaPreview={handleMediaPreview}
                            templatesList={templatesList}
                            funnerUsers={funnerUsers}
                            isFunnerAvatarVisible={isFunnerAvatarVisible}
                            chatCustomizationSettings={
                              chatCustomizationSettings
                            }
                            selectable={
                              forwardMessage.visible ||
                              forwardMessagesWebhookSelectable
                            }
                            selected={
                              forwardMessage.visible
                                ? forwardMessage.messagesList.includes(
                                    message.Id,
                                  )
                                : forwardMessagesWebhookSelectable
                                ? forwardMessagesWebhookList.some(
                                    (msg) => msg.Id === message.Id,
                                  )
                                : false
                            }
                            toggleSelected={
                              forwardMessage.visible
                                ? toggleMessageInForwardList
                                : forwardMessagesWebhookList !== null
                                ? toggleMessageInWebhookList
                                : null
                            }
                            onVideoMessageLoaded={onVideoMessageLoaded}
                            chatUsersGlobalRef={chatUsersGlobalRef}
                            scrollToMessage={scrollToMessage}
                          />
                        )}
                      </Fragment>
                    );
                  })}

                  {userData?.IsActive === false && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="error">
                        {
                          localizations["he"].chat.messages.systemAlerts
                            .notActive
                        }
                      </Alert>
                    </Box>
                  )}
                  {selectedChatUser &&
                    !isActive &&
                    // !messagesLoading &&
                    userData?.IsActive && (
                      <Box sx={{ mt: 2 }}>
                        <Alert severity="error">
                          {
                            localizations["he"].chat.messages.systemAlerts
                              .chatUserClosed
                          }
                        </Alert>
                      </Box>
                    )}
                  {/* {selectedChatUserConfig &&
                  // !messagesLoading &&
                  selectedChatUserConfig?.isBotActive && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="info" color="success">
                        {localizations["he"].chat.messages.systemAlerts.botMode}
                      </Alert>
                    </Box>
                  )} */}

                  <MessagesContextMenu
                    messagesContextRef={messagesContextRef}
                    messagesContextState={messagesContextState}
                    closeMessagesContext={closeMessagesContext}
                    handleSendReply={handleSendReply}
                    selectedChatUser={selectedChatUser}
                    onForwardContextClick={onForwardContextClick}
                    forwardMessagesWebhookEnabled={
                      forwardMessagesWebhook.status
                    }
                    forwardMessagesToWebhook={forwardMessagesToWebhook}
                    sendSingleMessageToWebhook={sendSingleMessageToWebhook}
                    toggleMessageInWebhookList={toggleMessageInWebhookList}
                    sendAllMessagesToWebhook={sendAllMessagesToWebhook}
                    closeForwardMessage={closeForwardMessage}
                    updateMessageFavourite={updateMessageFavourite}
                    setForwardMessagesWebhookSelectable={
                      setForwardMessagesWebhookSelectable
                    }
                    textareaRef={textareaRef}
                  />

                  {sendEmojiReply.visible && (
                    <EmojiReplyTemplate
                      emojiReplyRef={emojiReplyRef}
                      sendEmojiReply={sendEmojiReply}
                      handleEmojiReply={handleEmojiReply}
                      messagesFeedRef={messagesFeedRef}
                      handleSendEmojiReply={handleSendEmojiReply}
                    />
                  )}

                  <div className="scroll-bottom" />
                </Box>
                <Zoom in={isGoBotBtn}>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      zIndex: 10,
                    }}
                  >
                    <Badge
                      // badgeContent={1}
                      badgeContent={
                        selectedChatUser
                          ? selectedChatUser.UnreadMessagesNumber
                          : undefined
                      }
                      slotProps={{
                        badge: {
                          style: {
                            visibility:
                              !selectedChatUser?.UnreadMessagesNumber ||
                              selectedChatUser?.UnreadMessagesNumber === 0
                                ? "hidden"
                                : undefined,
                          },
                        },
                      }}
                      overlap="circular"
                      color="error"
                      invisible={
                        !selectedChatUser?.UnreadMessagesNumber ||
                        selectedChatUser?.UnreadMessagesNumber === 0
                      }
                    >
                      <Fab
                        onClick={() => scrollToLastMessage()}
                        color="primary"
                        size="small"
                      >
                        <KeyboardArrowDown />
                      </Fab>
                    </Badge>
                  </Box>
                </Zoom>
                {messagesLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 1000000000000000000000n,
                      // bgcolor: "#f5f5f5",
                      background:
                        chatCustomizationSettings?.find(
                          (option) => option.type === "conversationBackground",
                        )?.value === "image"
                          ? `url(${
                              chatCustomizationSettings.find(
                                (option) =>
                                  option.type === "conversationBackground",
                              ).customColor.backgroundImage
                            }) center / cover no-repeat`
                          : chatCustomizationSettings?.find(
                              (option) =>
                                option.type === "conversationBackground",
                            )?.value === "color"
                          ? chatCustomizationSettings.find(
                              (option) =>
                                option.type === "conversationBackground",
                            ).customColor.background
                          : "#fff",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    {[...Array(15)].map((_, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          mb: 2.25,
                          px: 1.25,
                          gap: 1.25,
                          flexDirection:
                            index % 2 === 0 ? "row-reverse" : "row",
                        }}
                      >
                        {userConfig.isDisplayName && index % 2 === 0 && (
                          <Skeleton
                            variant="circular"
                            width={32}
                            height={32}
                            sx={{ mt: -1.5 }}
                          />
                        )}

                        <Box
                          sx={{
                            width: 250,
                            position: "relative",
                            pl: index % 2 === 0 ? 0 : 1.25,
                            pr: index % 2 === 0 ? 1.25 : 0,
                            "&:after": {
                              content: '""',
                              display: "block",
                              width: 0,
                              height: 0,
                              borderRight:
                                index % 2 === 0
                                  ? "10px solid transparent"
                                  : "none",
                              borderLeft:
                                index % 2 === 0
                                  ? "none"
                                  : "10px solid transparent",
                              borderTop: "15px solid #e0e0e0",
                              position: "absolute",
                              top: 0,
                              left: index % 2 === 0 ? undefined : 0,
                              right: index % 2 === 0 ? 0 : undefined,
                            },
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={65}
                            sx={{
                              borderTopRightRadius:
                                index % 2 === 0 ? 0 : undefined,
                              borderTopLeftRadius:
                                index % 2 === 0 ? undefined : 0,
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </div>

              {sendReply?.isReply && (
                <SendReplyTemplate
                  sendReply={sendReply}
                  closeHandler={() => {
                    resetSendReply();
                  }}
                  templatesList={templatesList}
                />
              )}
              {sendUpload?.isUpload && (
                <FileUploadPreview
                  sendUpload={sendUpload}
                  closeHandler={() => {
                    resetSendUpload();
                  }}
                />
              )}
              {sendUploadPriority?.isUpload && (
                <FileUploadPreview
                  sendUpload={sendUploadPriority}
                  closeHandler={() => {
                    resetSendUploadPriority();
                  }}
                />
              )}

              <Dialog
                onClose={() => closePreviewPopup()}
                open={mediaPopupView.visible}
                maxWidth={"md"}
              >
                <DialogTitle>
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item xs="auto">
                      <IconButton
                        // onClick={() => closePreviewPopup()}
                        href={mediaPopupView.file}
                        target="_blank"
                      >
                        <OpenInNew />
                      </IconButton>
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton onClick={() => closePreviewPopup()}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  {mediaPopupView.type === "image" && (
                    <Link
                      href={mediaPopupView.file}
                      target="_blank"
                      sx={{ display: "block", maxHeight: "100%" }}
                    >
                      <img
                        src={mediaPopupView.file}
                        alt=""
                        style={{
                          maxWidth: 835,
                          maxHeight: "75vh",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  )}
                  {mediaPopupView.type === "pdf" && (
                    <iframe
                      title={mediaPopupView.file}
                      src={mediaPopupView.file}
                      style={{
                        maxWidth: "100%",
                        width: 700,
                        display: "block",
                        height: "80vh",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </DialogContent>
              </Dialog>
              {/* {mediaPopupView.visible && (
              <MediaPopup
                mediaPopupView={mediaPopupView}
                handleMediaPreview={handleMediaPreview}
              />
            )} */}

              <ForwardChatUsersPopup
                open={forwardMessage.chatUsersPopup}
                onClose={closeForwardChatUsersPopup}
                onSubmit={() => handleSendForwardMessages(forwardMessage)}
                forwardMessage={forwardMessage}
                chatUsersGlobal={chatUsersGlobal}
                toggleChatUsersInForwardList={toggleChatUsersInForwardList}
              />
              <ChatInputArea
                isActive={isActive}
                textareaRef={textareaRef}
                sendToPlatformsStatus={sendToPlatformsStatus}
                switchToChatType={switchToChatType}
                selectedChatUser={selectedChatUser}
                toggleAccordion={toggleAccordion}
                businessInfo={businessInfo}
                isMessageSending={isMessageSending}
                handleForm={handleForm}
                handleUpload={handleUpload}
                sendUpload={sendUpload}
                sendUploadPriority={sendUploadPriority}
                funnerUsers={funnerUsers}
                userConfig={userConfig}
                handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
                isTemplateSending={isTemplateSending}
                resetTemplateFromChat={resetTemplateFromChat}
                chatType={chatType}
                isSmileBottomPanelOpen={isSmileBottomPanelOpen}
                setIsSmileBottomPanelOpen={setIsSmileBottomPanelOpen}
                forwardMessage={forwardMessage}
                handleForwardMessage={handleForwardMessage}
                closeForwardMessage={closeForwardMessage}
                forwardMessagesWebhookList={forwardMessagesWebhookList}
                handleMessagesToWebhookPopup={handleMessagesToWebhookPopup}
                forwardMessagesWebhookSelectable={
                  forwardMessagesWebhookSelectable
                }
                resetForwardMessagesToWebhook={resetForwardMessagesToWebhook}
                isButtonsPopupForForwardMessagesWebhook={
                  isButtonsPopupForForwardMessagesWebhook
                }
                forwardMessagesToWebhook={forwardMessagesToWebhook}
                handleDirectFileUpload={handleDirectFileUpload}
              />

              <ForwardMessagesToWebhookPreview
                open={forwardMessagesWebhookPreview}
                onClose={() => {
                  handleMessagesToWebhookPopup(false);
                  resetForwardMessagesToWebhook();
                }}
                onSubmit={forwardMessagesToWebhook}
                userConfig={userConfig}
                chatCustomizationSettings={chatCustomizationSettings}
                messages={forwardMessagesWebhookList}
                scrollToMessage={scrollToMessage}
                templatesList={templatesList}
                funnerUsers={funnerUsers}
                chatUsersGlobalRef={chatUsersGlobalRef}
                handleMediaPreview={handleMediaPreview}
                selectedUserId={selectedUserId}
                companyId={businessInfo?.CompanyId}
                companyPlatforms={businessInfo?.CompanyPlatforms}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default Chat;
